import { TableRow } from "@mui/material";
import StyledTableCell from "./Styled/StyledTableCell";

const DeviceRow = ({ index, device }) => {
  return (
    <TableRow key={index}>
      <StyledTableCell>
        {device.group}
      </StyledTableCell>
      <StyledTableCell>
        {device.type}
      </StyledTableCell>
      <StyledTableCell>
        {device.model}
      </StyledTableCell>
      <StyledTableCell>
        {device.serialNumber}
      </StyledTableCell>
      <StyledTableCell>
        {device.hardwareId}
      </StyledTableCell>
    </TableRow>
  );
};

export default DeviceRow;
