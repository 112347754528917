import { TableRow, Box } from "@mui/material";
import StyledTableCell from "./Styled/StyledTableCell";

const HistoryRow = ({ index, data }) => {
  return (
    <TableRow key={index}>
      <StyledTableCell>
        <Box>{data.product}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box>{new Date(data.creationTime.replace("Z", "")).toLocaleDateString("ru-RU")}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box>{new Date(data.validTill.replace("Z", "")).toLocaleDateString("ru-RU")}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box>{data.issuedBy}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box>{data.holder}</Box>
      </StyledTableCell>
    </TableRow>
  );
};

export default HistoryRow;
