import { TableRow, Tooltip, Box, Typography, Switch } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ActionIconButton from "./ActionIconButton";
import EditUserDialog from "./Dialogs/EditUserDialog";
import CustomCheckbox from "./Styled/CustomCheckbox";
import StyledTableCell from "./Styled/StyledTableCell";

const UsersRow = ({ index, user, props }) => {
  return (
    <>
      <TableRow key={index}>
        <StyledTableCell>
          <Box textAlign="center">
            <EditUserDialog user={user} disabled={user.id === props.currentUserId}>
              <EditIcon />
            </EditUserDialog>
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="left">
            {user.description || user.company ? (
              <Tooltip
                title={
                  <>
                    {user.company && (
                      <Box
                        style={{ fontWeight: "bold" }}
                        marginBottom={user.description ? "5px" : "0px"}
                      >
                        {user.company?.name}
                      </Box>
                    )}
                    {user.description && <Box>{user.description}</Box>}
                  </>
                }
                placement="bottom-start"
              >
                <Box>{`${user.name} ${user.surname}`}</Box>
              </Tooltip>
            ) : (
              `${user.name} ${user.surname}`
            )}
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="left">{user.email}</Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="center">
            <Switch
              disabled={user.id === props.currentUserId}
              checked={user.isActive}
              onChange={() => {
                props.handleUserActivation(user.id, !user.isActive);
              }}
            />
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="center">
            <CustomCheckbox
              disabled={user.id === props.currentUserId || !user.isActive}
              checked={user.permissions?.downloadLicense}
              onChange={() =>
                props.changePermissions(user.id, {
                  ...user.permissions,
                  downloadLicense: !user.permissions.downloadLicense,
                })
              }
            />
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="center">
            <CustomCheckbox
              disabled={user.id === props.currentUserId || !user.isActive}
              checked={user.permissions?.addLicense}
              onChange={() =>
                props.changePermissions(user.id, {
                  ...user.permissions,
                  addLicense: !user.permissions.addLicense,
                })
              }
            />
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="center">
            <CustomCheckbox
              disabled={user.id === props.currentUserId || !user.isActive}
              checked={user.permissions?.seeAllCustomers}
              onChange={() =>
                props.changePermissions(user.id, {
                  ...user.permissions,
                  seeAllCustomers: !user.permissions.seeAllCustomers,
                })
              }
            />
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="center">
            <CustomCheckbox
              disabled={user.id === props.currentUserId || !user.isActive}
              checked={user.permissions?.editCompanyInfo}
              onChange={() =>
                props.changePermissions(user.id, {
                  ...user.permissions,
                  editCompanyInfo: !user.permissions.editCompanyInfo,
                })
              }
            />
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="center">
            <CustomCheckbox
              disabled={user.id === props.currentUserId || !user.isActive}
              checked={user.permissions?.editHolderInfo}
              onChange={() =>
                props.changePermissions(user.id, {
                  ...user.permissions,
                  editHolderInfo: !user.permissions.editHolderInfo,
                })
              }
            />
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="center">
            <CustomCheckbox
              disabled={user.id === props.currentUserId || !user.isActive}
              checked={user.permissions?.editUserInfo}
              onChange={() =>
                props.changePermissions(user.id, {
                  ...user.permissions,
                  editUserInfo: !user.permissions.editUserInfo,
                })
              }
            />
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="center">
            <CustomCheckbox
              disabled={user.id === props.currentUserId || !user.isActive}
              checked={user.permissions?.editProduct}
              onChange={() =>
                props.changePermissions(user.id, {
                  ...user.permissions,
                  editProduct: !user.permissions.editProduct,
                })
              }
            />
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="center">
            <CustomCheckbox
              disabled={user.id === props.currentUserId || !user.isActive}
              checked={user.permissions?.workWithArchive}
              onChange={() =>
                props.changePermissions(user.id, {
                  ...user.permissions,
                  workWithArchive: !user.permissions.workWithArchive,
                })
              }
            />
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="center">
            <CustomCheckbox
              disabled={user.id === props.currentUserId || !user.isActive}
              checked={user.permissions?.workWithDatabase}
              onChange={() =>
                props.changePermissions(user.id, {
                  ...user.permissions,
                  workWithDatabase: !user.permissions.workWithDatabase,
                })
              }
            />
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box textAlign="center">
            <ActionIconButton
              disabled={user.id === props.currentUserId}
              title="Delete user?"
              contentText="If you delete the user, you couldn't revert this action. Information about this user will still be in the licenses issued by him."
              confirmText="Delete"
              rejectText="Cancel"
              onClick={() => props.deleteUser(user.id)}
            >
              <DeleteIcon />
            </ActionIconButton>
          </Box>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

export default UsersRow;
