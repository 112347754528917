import React from "react";

const styles = {
  container: {
    WebkitFontSmoothing: "antialiased",
    borderCollapse: "collapse",
    borderSpacing: 0,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.43,
    letterSpacing: "0.01071em",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "center",
  },
  mainSpan: {
    WebkitFontSmoothing: 'antialiased',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    textAlign: 'center',
    display: 'inline-flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    WebkitBoxPack: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    WebkitTapHighlightColor: 'transparent',
    backgroundColor: 'transparent',
    outline: 0,
    border: 0,
    margin: 0,
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    WebkitAppearance: 'none',
    textDecoration: 'none',
    padding: '9px',
    borderRadius: '50%',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  input: {
    WebkitFontSmoothing: 'antialiased',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    WebkitTapHighlightColor: 'transparent',
    userSelect: 'none',
    cursor: 'inherit',
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    zIndex: 1,
  },
  svg: {
    WebkitFontSmoothing: 'antialiased',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    textAlign: 'center',
    WebkitTapHighlightColor: 'transparent',
    cursor: 'pointer',
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentColor',
    flexShrink: 0,
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
  },
  background: {
    WebkitFontSmoothing: 'antialiased',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    textAlign: 'center',
    WebkitTapHighlightColor: 'transparent',
    cursor: 'pointer',
    userSelect: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
    overflow: 'hidden',
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 'inherit',
  }
};

const CustomCheckbox = ({ checked, onChange, label, disabled }) => {
  return (
    <>
      <div style={styles.container}>
        <span style={styles.mainSpan}>
          <input
            disabled={disabled}
            style={styles.input}
            type="checkbox"
            data-indeterminate="false"
            checked={checked}
            onChange={onChange}
          />
          <svg
            color={disabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.6)'}
            style={styles.svg}
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid={checked ? "CheckBoxIcon" : "CheckBoxOutlineBlankIcon"}
          >
            {checked ? (
              <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
            ) : (
              <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
            )}
          </svg>
          <span style={styles.background}></span>
        </span>
      </div>
    </>
  );
};

export default CustomCheckbox;
