import EditProductDialog from "../components/Dialogs/EditProductDialog";
import { TableRow, Box } from "@mui/material";
import StyledTableCell from "./Styled/StyledTableCell";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ActionIconButton from "./ActionIconButton";
import { DEFAULT_STRING } from "../constants/defaultStrings";

const ProductRow = ({
  index,
  product,
  removeProduct,
  devices,
  deviceTypes,
  updateProduct,
  isLoading,
}) => {
  return (
    <TableRow key={index}>
      <StyledTableCell>
        <Box textAlign="left">{product.name || DEFAULT_STRING.NO_NAME}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box textAlign="left">
          {product.models && product.models.join(", ")}
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box textAlign="left">{product.description}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box textAlign="center">
          <EditProductDialog
            product={product}
            updateProduct={updateProduct}
            devices={devices}
            deviceTypes={deviceTypes}
            isLoading={isLoading}
          >
            <EditIcon />
          </EditProductDialog>
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box textAlign="center">
          <ActionIconButton
            title="Delete product?"
            contentText="If you delete the product, you couldn't revert this action. Information about this product will still be in the history."
            confirmText="Delete"
            rejectText="Cancel"
            onClick={() => removeProduct(product.id)}
          >
            <DeleteIcon />
          </ActionIconButton>
        </Box>
      </StyledTableCell>
    </TableRow>
  );
};

export default ProductRow;
