import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../hooks/useAuth";
import { userAPI } from "../services/api/userAPI";
import { setPermissions } from "../store/slices/permissionsSlise";

export const withAuth = (Component) => {
    const WithAuthComponent = (props) => {
        const { user } = useAuth();
        const { permissions } = useSelector((state) => state.permissions);
        const dispatch = useDispatch();

        useEffect(async () => {
            if (user) {
              let response = await userAPI.get(user.id);
              if (response.status === 200) {
                if(!Boolean(permissions) || Object.keys(permissions).length === 0 || JSON.stringify(user.permissions) !== JSON.stringify(response.data.permissions)){
                  localStorage.setItem("user", JSON.stringify({...response.data, token: user.token}));
                  dispatch(setPermissions(response.data.permissions));
                }
              }
            }
          }, []);

        return <Component {...props}/>
    }

    return WithAuthComponent;
}