import { Grid, Typography } from "@mui/material";
import React from "react";

function ProfileInfo(props) {
  var { name, surname, email, phoneNumber, description, company } = props;

  return (
    <div>
      <Typography variant="h6" margin="20px 0 20px 0">
        User information
      </Typography>
      <Grid container spacing={1}>
        <Grid item container spacing={3} alignItems="center">
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              User name:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {name} {surname}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={3} alignItems="center">
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Company name:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{company?.name}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={3} alignItems="center">
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Email:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{email}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={3} alignItems="center">
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Phone:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{phoneNumber}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={3} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <Grid item>
              <Typography variant="subtitle2" color="textSecondary">
                Details:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" style={{ marginLeft: "24px" }}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ProfileInfo;
