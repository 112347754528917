import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchBar from "../components/SearchBar";
import useDeviceData from "../hooks/useDeviceData";
import ComboboxFilter from "../components/ComboboxFilter";
import useProducts from "../hooks/useProducts";

const styles = {
  searchBarBox: {
    marginBottom: "20px",
  },
  filtersHeaderBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clearButton: {
    width: "90px",
    height: "25px",
    textTransform: "none",
    color: "grey",
    borderColor: "grey",
  },
  filtersBox: {
    height: "696px",
  },
  dropDownListsContainer: {
    maxHeight: "700px",
    overflow: "auto",
  },
};

const HolderHistoryFilterBox = ({isLoading, collection, onChange}) => {
  const [searched, setSearched] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState([]);
  const { deviceCategories, deviceTypes, deviceModels, deviceSerialNumbers } = useDeviceData();
  const {productNames} = useProducts();

  useEffect(() => {
    applyFilters(selectedCategories, selectedTypes, selectedModels, selectedSerialNumbers, selectedProducts, searched);
  }, [collection]);

  const cancelSearch = () => {
    requestSearch("");
  };

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    applyFilters(selectedCategories, selectedTypes, selectedModels, selectedSerialNumbers, selectedProducts, searchedVal);
  };

  const clearFilters = () => {
    setSelectedCategories([]);
    setSelectedTypes([]);
    setSelectedModels([]);
    setSelectedSerialNumbers([]);
    setSelectedProducts([]);
    applyFilters([], [], [], [], [], searched);
  };

  const applyFilters = (categories, types, models, serialNumbers, products, searched) => {
    let filteredRows = collection;

    // category
    if (categories && categories.length > 0)
      filteredRows = filteredRows.filter((row) =>
        categories.includes(row.deviceGroup)
      );

    // type
    if (types && types.length > 0)
      filteredRows = filteredRows.filter((row) => types.includes(row.deviceType));

    // model
    if (models && models.length > 0)
    filteredRows = filteredRows.filter((row) => models.includes(row.scannerModel));

    // serial number
    if (serialNumbers && serialNumbers.length > 0)
      filteredRows = filteredRows.filter((row) => serialNumbers.includes(row.scannerSerialNumber));

    // product
    if (products && products.length > 0)
    filteredRows = filteredRows.filter((row) => products.includes(row.product));
    
    // rearch
    if (searched) {
      filteredRows = filteredRows.filter((row) => {
        return [
          row.deviceGroup,
          row.deviceType,
          row.scannerModel,
          row.scannerSerialNumber,
          new Date(row.creationTime.replace("Z", "")).toLocaleDateString("ru-RU"),
          new Date(row.validTill.replace("Z", "")).toLocaleDateString("ru-RU"),
          row.issuedBy,
          row.holder,
          row.product,
        ].some((x) => x?.toLowerCase().includes(searched.toLowerCase()));
      });
    }
    onChange(filteredRows);
  };

  const onCategoryFilterChanged = (categories) => {
    setSelectedCategories(categories);
    applyFilters(categories, selectedTypes, selectedModels, selectedSerialNumbers, selectedProducts, searched);
  };

  const onTypeFilterChanged = (types) => {
    setSelectedTypes(types);
    applyFilters(selectedCategories, types, selectedModels, selectedSerialNumbers, selectedProducts, searched);
  };

  const onModelFilterChanged = (models) => {
    setSelectedModels(models);
    applyFilters(selectedCategories, selectedTypes, models, selectedSerialNumbers, selectedProducts, searched);
  };

  const onSerialNumberFilterChanged = (numbers) => {
    setSelectedSerialNumbers(numbers);
    applyFilters(selectedCategories, selectedTypes, selectedModels, numbers, selectedProducts, searched);
  };

  const onProductFilterChanged = (products) => {
    setSelectedProducts(products);
    applyFilters(selectedCategories, selectedTypes, selectedModels, selectedSerialNumbers, products, searched);
  };

  return (
    <>
      <div style={styles.searchBarBox}>
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
      </div>
      <div style={styles.filtersHeaderBox}>
        <Typography>Filters</Typography>
        <Button
          disabled={isLoading}
          variant="outlined"
          size="small"
          style={styles.clearButton}
          onClick={clearFilters}
        >
          Clear
        </Button>
      </div>
      <div style={styles.filtersBox}>
        <div style={styles.dropDownListsContainer}>
          <ComboboxFilter
            label="Category"
            selectedItems={selectedCategories}
            items={deviceCategories}
            onChange={onCategoryFilterChanged}
          />
          <ComboboxFilter
            label="Type"
            selectedItems={selectedTypes}
            items={deviceTypes}
            onChange={onTypeFilterChanged}
          />
          <ComboboxFilter
            label="Scanner model"
            selectedItems={selectedModels}
            items={deviceModels}
            onChange={onModelFilterChanged}
          />
          <ComboboxFilter
            label="Serial number"
            selectedItems={selectedSerialNumbers}
            items={deviceSerialNumbers}
            onChange={onSerialNumberFilterChanged}
          />
          <ComboboxFilter
            label="Product"
            selectedItems={selectedProducts}
            items={productNames}
            onChange={onProductFilterChanged}
          />
        </div>
      </div>
    </>
  );
}

export default HolderHistoryFilterBox;
