import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { withAuth } from "../hocs/withAuth";
import { useAuth } from "../hooks/useAuth";
import HeaderItem from "./HeaderItem";
import ActionButton from "./ActionButton";

function Header(props) {
  const { permissions } = useSelector((state) => state.permissions);
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    try {
      localStorage.clear();
      navigate("/login");
    } catch (e) {
      console.log(e);
    }
  };

  return (
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            {isAuth && (
              <HeaderItem to="/">
                Licenses
              </HeaderItem>
            )}
            {isAuth && permissions?.editUserInfo && (
              <HeaderItem to="/user-management">
                Users
              </HeaderItem>
            )}
            {isAuth && permissions?.editProduct && (
              <HeaderItem to="/product-management">
                Products
              </HeaderItem>
            )}
            {isAuth && (
              <HeaderItem to={`/profile`}>
                Profile
              </HeaderItem>
            )}
            {isAuth && (
              <HeaderItem to={`/archive`}>
                Archive
              </HeaderItem>
            )}
             {isAuth && permissions?.workWithDatabase && (
              <HeaderItem to={`/devices`}>
                Devices
              </HeaderItem>
            )}
          </Box>
          {isAuth ? (
            <ActionButton
              color="inherit"
              variant="string"
              title="You are leaving the service"
              contentText="Are you sure you want to log out?"
              confirmText="Log Out"
              rejectText="Cancel"
              onClick={logout}
            >
              Log Out
            </ActionButton>
          ) : (
            <Button color="inherit" component={Link} to="/login">
              Log In
            </Button>
          )}
        </Toolbar>
      </AppBar>
  );
}

export default withAuth(Header);
