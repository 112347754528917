import {
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { withAuth } from "../hocs/withAuth";
import { useAuth } from "../hooks/useAuth";
import Loader from "../components/Loader/Loader";
import DeviceRow from "../components/DeviceRow";
import StyledHeadCell from "../components/Styled/StyledHeadCell";
import CreateDeviceDialog from "../components/Dialogs/CreateDeviceDialog";
import useDeviceData from "../hooks/useDeviceData";

function DevicesPage() {
  const { permissions } = useSelector((state) => state.permissions);
  const {
    devices,
    deviceCategories,
    isLoading,
    addDevice,
  } = useDeviceData();
  const { isAuth } = useAuth();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  if (permissions && !permissions?.workWithDatabase) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Container maxWidth={false} style={{ padding: "0px 35px 65px 35px " }}>
      <title>Device database</title>
      <h2>Device database</h2>
      <CreateDeviceDialog
        devices={devices}
        deviceCategories={deviceCategories}
        isLoading={isLoading}
        addDevice={addDevice}
      />
      <Typography variant="h6" margin="20px 0">
        Available devices
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHeadCell>Category</StyledHeadCell>
              <StyledHeadCell>Scanner type</StyledHeadCell>
              <StyledHeadCell>Scanner model</StyledHeadCell>
              <StyledHeadCell>Serial number</StyledHeadCell>
              <StyledHeadCell>Scanner key</StyledHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devices
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((device, index) => (
                <DeviceRow index={index} device={device} />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={devices?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {isLoading && <Loader />}
    </Container>
  );
}

export default withAuth(DevicesPage);
