import { Button, Container, Grid } from "@mui/material";
import { Link } from "react-router-dom";

function ResetPasswordConfirmation() {
  return (
    <Container maxWidth="xl">
      <title>Reset password confirmation</title>
      <h1>Your password was reset!</h1>
      <h2>Check the email and see new login details.</h2>
      <Grid item xs={12}>
        <Button variant="contained" component={Link} to="/login">
          Ok
        </Button>
      </Grid>
    </Container>
  );
}

export default ResetPasswordConfirmation;
