import {
  Container,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { withAuth } from "../hocs/withAuth";
import { useAuth } from "../hooks/useAuth";
import Loader from "../components/Loader/Loader";
import HolderHistoryRow from "../components/HolderHistoryRow";
import StyledHeadCell from "../components/Styled/StyledHeadCell";
import { historyAPI } from "../services/api/historyAPI";
import { notifyResponseError } from "../helpers/notificationHelper";
import { useSelector } from "react-redux";
import HolderInfoForm from "../components/HolderInfoForm";
import HolderHistoryFilterBox from "../components/HolderHistoryFilterBox";

const styles = {
  mainContainer: {
    padding: "0px 0px 65px 0px ",
  },
  filterContainer: {
    borderRight: "1px solid black",
    padding: "15px",
    width: "275px",
  },
  contentContainer: {
    flexGrow: 1,
    padding: "5px 25px",
  },
};

function HolderPage() {
  const { id } = useParams();
  const { isAuth } = useAuth();
  const [historyData, setHistoryData] = useState([]);
  const [filteredHistoryData, setFilteredHistoryData] = useState(historyData);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { permissions } = useSelector((state) => state.permissions);

  useEffect(async () => {
    if (permissions && permissions.editHolderInfo) {
      loadHistory();
    }
  }, [permissions]);

  const loadHistory = async () => {
    setIsHistoryLoading(true);
    const response = await historyAPI.getByHolderId(id);
    if (response.status === 200) {
      setHistoryData(response.data.reverse());
    } else {
      notifyResponseError(response, "Failed to load history");
    }
    setIsHistoryLoading(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateHistoryByFilter = (filteredData) => {
    setFilteredHistoryData(filteredData);
    setPage(0);
  };

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  if (permissions && !permissions?.editHolderInfo) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Container maxWidth={false} style={styles.mainContainer}>
      <title>Holder history</title>
      <Grid container direction="row">
        <Grid style={styles.filterContainer}>
          <HolderHistoryFilterBox
            collection={historyData}
            onChange={updateHistoryByFilter}
            isLoading={isHistoryLoading}
          />
        </Grid>
        <Grid item style={styles.contentContainer}>
          <HolderInfoForm id={id} />
          <Typography variant="h6" margin="15px 0">
            License holder history
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledHeadCell>License issuer</StyledHeadCell>
                  <StyledHeadCell>Scanner model</StyledHeadCell>
                  <StyledHeadCell>Serial number</StyledHeadCell>
                  <StyledHeadCell>Product</StyledHeadCell>
                  <StyledHeadCell>Issue date</StyledHeadCell>
                  <StyledHeadCell>Valid till</StyledHeadCell>
                  <StyledHeadCell>Issued by</StyledHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredHistoryData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <HolderHistoryRow index={index} data={data} />
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={filteredHistoryData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
      {isHistoryLoading && <Loader />}
    </Container>
  );
}

export default withAuth(HolderPage);
