import * as axios from "axios";
import { API_URL } from "../constants/URLs";

const $api = axios.create({
  baseURL: API_URL,
  //withCredentials: true,
});

$api.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    config.headers.Authorization = `Bearer ${user.token}`;
  }
  return config;
});

export default $api;
