import * as yup from 'yup';
import * as Date from "../helpers/datePrototypes";
import phoneSchema from './phoneSchema';

const step1Schema = yup.object().shape({
  type: yup.string().required(),
  scannerModel: yup.string().required(),
  scannerSerialNumber: yup.string().required().matches(/^[^\s].*[^\s]$/, 'The serial number must not start or end with a space'),
  scannerKey: yup.string().min(32).max(32).required(),
});

const step2Schema = yup.object().shape({
  company: yup.string().required(),
  contactPersonName: yup.string().required(),
  email: yup.string().email().required(),
  phoneNumber: phoneSchema,
  detailes: yup.string(),
});

const step3Schema = yup.object().shape({
  product: yup.object().required(),
  validTill: yup
    .date()
    .required()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .min(
      new Date(),
      `validTill field must be later than ${new Date().toLocaleDateString(
        "ru-RU"
      )}`
    ),
    generateOldVersion: yup.bool(),
});

// Общая схема валидации, объединяющая схемы для каждого этапа
const validationSchema = yup.object().shape({
  ...step1Schema.fields,
  ...step2Schema.fields,
  ...step3Schema.fields,
});

export { step1Schema, step2Schema, step3Schema, validationSchema };