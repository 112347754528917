import { Box } from "@mui/material";
import React from "react";

const CompanyToolTipTitle = ({ product }) => {
  return (
    <>
      <Box
        style={{ margin: "4px" }}
        color={product?.creator?.isDisable ? "text.secondary" : "white"}
      >{`${product?.creator?.name || ""} ${
        product?.creator?.surname || ""
      }`}</Box>
      <Box style={{ margin: "4px" }}>{product?.creator?.email || ""}</Box>
      <Box style={{ margin: "6px" }}>{product?.creator?.phoneNumber || ""}</Box>
      <Box style={{ margin: "4px" }}>{product?.creator?.description || ""}</Box>
    </>
  );
};

export default CompanyToolTipTitle;
