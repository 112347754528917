import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
};

const usersSlise = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers(state, action) {
      state.users = action.payload.filter((user) => !user.isDisable);
    },
    toggleActivate(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload);
      if (userIndex === -1) return;
      state.users[userIndex].isActive = !state.users[userIndex].isActive;
    },
    updateUser(state, action) {
      let userIndex = state.users.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (userIndex === -1) return;
      state.users[userIndex] = { ...action.payload };
    },
    updatePermissions(state, action) {
      let userIndex = state.users.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (userIndex === -1) return;
      state.users[userIndex].permissions = {...action.payload.permissions };
    },
  },
});

export const {
  toggleActivate,
  setUsers,
  updatePermissions,
  updateUser,
} = usersSlise.actions;

export default usersSlise.reducer;
