import $api from "../axios";
import { handleResponse } from "../../helpers/httpHelpers";

export const licenseAPI = {
  download,
  create,
  getAll,
  getByScanner,
  getArchived,
  getNotArchived,
  getByCreator,
  moveToArchive,
  remove,
  getCompany,
  updateCompany,
  getFiltered
};

async function getAll() {
  try {
    return await $api.get(`/License`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getByScanner(model, serialNumber) {
  try {
    return await $api.get(`/license/getByScanner?model=${model}&serialNumber=${serialNumber}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getArchived() {
  try {
    return await $api.get(`/License/getArchived`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getNotArchived() {
  try {
    return await $api.get(`/License/getNotArchived`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getByCreator() {
  try {
    return await $api.get(`/license/getByCreator`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function download(id) {
  try {
    return await $api.get(`/license/download?id=${id}`, {
      responseType: "arraybuffer",
    });
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function create(data) {
  try {
    return await $api.post("/license/create", data);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getFiltered(filters) {
  try {
    return await $api.post(`/license/getFiltered`, filters);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function moveToArchive(id) {
  try {
    return await $api.get(`/license/moveToArchive?id=${id}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function remove(id) {
  try {
    return await $api.delete(`/license/remove?id=${id}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getCompany(id) {
  try {
    return await $api.get(`/license/getCompanyById?id=${id}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function updateCompany(info) {
  try {
    return await $api.put(`/license/updateCompany`, info);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}
