import {
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { withAuth } from "../hocs/withAuth";
import { useAuth } from "../hooks/useAuth";
import Loader from "../components/Loader/Loader";
import HistoryRow from "../components/HistoryRow";
import StyledHeadCell from "../components/Styled/StyledHeadCell";
import { historyAPI } from "../services/api/historyAPI";
import { notifyResponseError } from "../helpers/notificationHelper";

function HistoryPage() {
  const { model, serialNumber } = useParams();
  const { isAuth } = useAuth();
  const [historyData, setHistoryData] = useState([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(async () => {
    if (model && serialNumber) {
      setIsHistoryLoading(true);
      const response = await historyAPI.get(model, serialNumber);
      if (response.status === 200) {
        setHistoryData(response.data.reverse());
      } else {
        notifyResponseError(response, "Failed to load history");
      }
      setIsHistoryLoading(false);
    }
  }, [model, serialNumber]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  return (
    <Container maxWidth={false} style={{ padding: "0px 35px 65px 35px" }}>
      <title>Device history</title>
      <Typography variant="h6" margin="20px 0">
        {`Scanner history for ${model} s/n: ${serialNumber}`}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHeadCell>Product</StyledHeadCell>
              <StyledHeadCell>Issue date</StyledHeadCell>
              <StyledHeadCell>Valid till</StyledHeadCell>
              <StyledHeadCell>Issued by</StyledHeadCell>
              <StyledHeadCell>License holder</StyledHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => (
                <HistoryRow index={index} data={data} />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={historyData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {isHistoryLoading && <Loader />}
    </Container>
  );
}

export default withAuth(HistoryPage);
