import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from "@mui/material";
import UsersRow from "./UsersRow";
import { useState } from "react";
import StyledHeadCell from "./Styled/StyledHeadCell";

function UsersTable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <h2>User Management</h2>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHeadCell align="center" style={{ minWidth: "50px" }}>
                Edit info
              </StyledHeadCell>
              <StyledHeadCell>
                User name
              </StyledHeadCell>
              <StyledHeadCell>
                Email
              </StyledHeadCell>
              <StyledHeadCell align="center">
                Active
              </StyledHeadCell>
              <StyledHeadCell align="center">
                Download license
              </StyledHeadCell>
              <StyledHeadCell align="center">
                Add new license
              </StyledHeadCell>
              <StyledHeadCell align="center">
                See all customers
              </StyledHeadCell>
              <StyledHeadCell align="center">
                Edit license issuer info
              </StyledHeadCell>
              <StyledHeadCell align="center">
                Edit license holder info
              </StyledHeadCell>
              <StyledHeadCell align="center">
                User management
              </StyledHeadCell>
              <StyledHeadCell align="center">
                Product management
              </StyledHeadCell>
              <StyledHeadCell align="center">
                Work with archive
              </StyledHeadCell>
              <StyledHeadCell align="center">
                Work with database
              </StyledHeadCell>
              <StyledHeadCell align="center">
                Delete account
              </StyledHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user, index) => (
                <UsersRow index={index} user={user} props={props} />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={props.users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}
export default UsersTable;
