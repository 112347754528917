import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Typography,
  Container,
  Grid,
  Autocomplete,
  Divider,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EditIcon from "@mui/icons-material/Edit";
import NotNullFieldFootnote from "../../../components/NotNullFieldFootnote";
import { step1Schema } from "../../../validationSchemas/createLicenseSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { deviceAPI } from "../../../services/api/deviceAPI";
import {
  notifyResponseError,
} from "../../../helpers/notificationHelper";

const styles = {
  stepTitle: {
    textAlign: "center",
    marginY: 2,
  },
  stepSubtitle: {
    marginY: 3,
  },
  stepAction: {
    textAlign: "right",
    marginY: 2,
  },
};

const CreateLicenseStepOne = ({
  resetSerialNumberDependentData,
  setProducts,
  loadProductByModel,
  loadHolderIfLicenseAlreadyExists,
  setStep1Data,
  step1Data,
  handleNext,
  updateModels,
  models,
  deviceTypes,
  scannerNumbers,
  setScannerNumbers,
  isScannerKeyRequered,
  setIsScannerKeyRequered,
  setScannerKey,
  scannerKey
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = React.useState(!isScannerKeyRequered);
  const { setValue, trigger, getValues, resetField, control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(step1Schema),
  });

  const scannerKeyInputRef = useRef();

  useEffect(() => {
    setValue("type", step1Data?.type ?? "");
    setValue("scannerModel", step1Data?.scannerModel ?? "");
    setValue("scannerSerialNumber", step1Data?.scannerSerialNumber ?? "");
    setValue("scannerKey", step1Data?.scannerKey ?? "");
  }, [step1Data]);

  useEffect(() => {
    setDisable(!isScannerKeyRequered);
    setValue("scannerKey", scannerKey ?? "");
     if (scannerKeyInputRef.current) {
       scannerKeyInputRef.current.value = scannerKey;
     }
    if(scannerKey){
      trigger("scannerKey");
    }
  }, [scannerKey, isScannerKeyRequered]);

  const handleEditModeClick = () => {
    setDisable(!disable);
  };

  const loadDeviceByModel = async (model) => {
    setIsLoading(true);
    let response = await deviceAPI.get({model});
    if (response.status === 200) {
      let numbers = [
        ...new Set(response.data.map((item) => item.serialNumber)),
      ].sort();
      setScannerNumbers(numbers);
    } else {
      notifyResponseError(response, "Failed to get device");
    }
    setIsLoading(false);
  };

  const onTypeChange = async (type) => {
    resetSerialNumberDependentData();
    type === null
      ? updateModels([])
      :  await loadDeviceByType(type);
  };

  const onModelChange = async (data) => {
    resetSerialNumberDependentData();
    if (data.scannerModel === null) {
      setScannerNumbers([]);
      setProducts([]);
    } else {
      await loadDeviceByModel(data.scannerModel);
      await loadProductByModel(data.scannerModel);
    }
  };

  const loadDeviceByType = async (type) => {
    setIsLoading(true);
    let response = await deviceAPI.get({type});
    if (response.status === 200) {
      const modelsResult = [
        ...new Set(response.data.map((item) => item.model)),
      ];
      updateModels(modelsResult);
    } else {
      notifyResponseError(response, "Failed to load devices");
    }
    setIsLoading(false);
  };

  const loadScannerKey = async (model, serialNumber) => {
    setIsLoading(true);
    let response = await deviceAPI.get({model, serialNumber});
    if (response.status === 200) {
      const key = response.data[0]?.hardwareId || "";
      setIsScannerKeyRequered(!Boolean(key));
      setScannerKey(key);
    } else {
      notifyResponseError(response, "Failed to load scanner key");
    }
    setIsLoading(false);
  };

  const onSerialNumberChange = async (data) => {
    resetSerialNumberDependentData();
    if(data.scannerSerialNumber){
      await loadScannerKey(data.scannerModel, data.scannerSerialNumber);
      await loadHolderIfLicenseAlreadyExists(data.scannerModel, data.scannerSerialNumber);
    }
  };

  const onSubmit = async (data) => {
    setStep1Data(data);
    setScannerKey(data.scannerKey);
    handleNext();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
    <Container>
      <Typography variant="h6" sx={styles.stepTitle}>
        Step 1: Scanner
      </Typography>
      <Typography variant="body2" sx={styles.stepSubtitle}>
        Please, set scanner characteristics:
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            name="type"
            control={control}
            render={(param) => (
              <Autocomplete
                {...param}
                value={getValues("type") ?? null}
                key={"Autocomplete" + param.field.name}
                disabled={isLoading}
                options={deviceTypes}
                getOptionLabel={(option) => option ?? ""}
                onChange={(_, type) => {
                  param.field.onChange(type);
                  onTypeChange(type);
                  resetField("scannerModel");
                  resetField("scannerSerialNumber");
                }}
                renderInput={(field) => (
                  <TextField
                    key={"TextField" + param.field.name}
                    {...field}
                    required
                    label="Scanner Type"
                    variant="outlined"
                    error={Boolean(errors.type?.message)}
                    helperText={errors.type?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            key={models}
            name="scannerModel"
            control={control}
            render={(param) => (
              <Autocomplete
                {...param}
                value={getValues("scannerModel") ?? null}
                key={models}
                disabled={isLoading}
                options={models}
                getOptionLabel={(option) => option ?? ""}
                onChange={(_, model) => {
                  param.field.onChange(model);
                  onModelChange(getValues());
                  resetField("scannerSerialNumber");
                  resetField("product");
                }}
                renderInput={(field) => (
                  <TextField
                    {...field}
                    required
                    label="Scanner Model"
                    variant="outlined"
                    error={Boolean(errors.scannerModel?.message)}
                    helperText={errors.scannerModel?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="scannerSerialNumber"
            control={control}
            render={(param) => (
              <Autocomplete
                {...param}
                freeSolo
                value={getValues("scannerSerialNumber") ?? null}
                key={scannerNumbers}
                disabled={isLoading}
                options={scannerNumbers}
                getOptionLabel={(option) => option ?? ""}
                onChange={(_, scannerNumber) => {
                  param.field.onChange(scannerNumber ?? "");
                  onSerialNumberChange(getValues());
                  resetField("scannerKey");
                }}
                onBlur={({ target }) => {
                  if (target.value) {
                    param.field.onChange(target.value);
                    onSerialNumberChange(getValues());
                    resetField("scannerKey");
                  }
                }}
                renderInput={(field) => (
                  <TextField
                    {...field}
                    required
                    label="Serial Number #"
                    variant="outlined"
                    error={Boolean(errors.scannerSerialNumber?.message)}
                    helperText={errors.scannerSerialNumber?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
          <Grid item xs={12}>
            <Controller
              key={getValues("scannerSerialNumber")}
              name="scannerKey"
              control={control}
              defaultValue={scannerKey}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  inputRef={scannerKeyInputRef}
                  disabled={disable}
                  variant="outlined"
                  fullWidth={true}
                  type="text"
                  label="Scanner Key"
                  error={Boolean(errors.scannerKey?.message)}
                  helperText={errors.scannerKey?.message}
                  InputProps={
                    {
                      endAdornment: (
                        <Box sx={{ display: "flex" }}>
                          <Divider orientation="vertical" flexItem />
                          <IconButton
                            onClick={handleEditModeClick}
                            edge="end"
                            style={{ margin: "0px -8px 0px 6px" }}
                          >
                            {disable ? (
                              <EditIcon />
                            ) : (
                              <DoneAllIcon color="primary" />
                            )}
                          </IconButton>
                        </Box>
                      ),
                    }
                  }
                />
              )}
            />
          </Grid>
        <NotNullFieldFootnote />
          <Container sx={styles.stepAction}>
            <Button type='submit'>Next</Button>
          </Container>
      </Grid>
    </Container>
    </form>
  );
};

export default CreateLicenseStepOne;
