import $api from "../axios";
import { handleResponse } from "../../helpers/httpHelpers";

export const authAPI = {
  register,
  login,
  restorePassword
};

async function register(user) {
  try {
    return await $api.post(`/users/add`, user);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function login(data) {
  try {
    return await $api.post(`/Users/authenticate`, {
      userName: data.email,
      password: data.password,
    });
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function restorePassword(email){
  try {
    return await $api.put(`/users/ResetPassword`, {email});
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}
