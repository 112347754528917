import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  licenses: [],
};

const licensesSlise = createSlice({
  name: "licenses",
  initialState,
  reducers: {
    setLicenses(state, action) {
      state.licenses = action.payload;
    },
    disableLicense(state, action) {
      let licenseIndex = state.licenses.findIndex(license => license.products.some(product => product.id === action.payload));
      if (licenseIndex === -1) return;
      let product = state.licenses[licenseIndex].products.filter(product => product.id === action.payload)[0];
      product.archived = true;
      state.licenses[licenseIndex].products = [product, ...state.licenses[licenseIndex].products.filter(product => product.id !== action.payload)]
    },
    deleteLicense(state, action) {
      let licenseIndex = state.licenses.findIndex(license => license.products.some(product => product.id === action.payload));
      if (licenseIndex === -1) return;
      state.licenses[licenseIndex].products = state.licenses[licenseIndex].products.filter(product => product.id !== action.payload);
    },
    addLicense(state, action) {
      let licenseIndex = state.licenses.findIndex((obj) => obj.id === action.payload.id);
      if (licenseIndex === -1){
        state.licenses = [action.payload, ...state.licenses];
      }else{
        state.licenses[licenseIndex] = action.payload;
      }
    },
    updateCompanyInfo(state, action) {
      const updatedLicenses = state.licenses.map(license => {
        const updatedProducts = license.products.map(product => {
          if (product.creator.companyName === action.payload.companyName) {
            product.creator.companyName = action.payload.newValue;
          }
          return product;
        });
        return {...license, products: updatedProducts};
      });
      state.licenses = updatedLicenses;
    },
  },
});

export const {
  setLicenses,
  addLicense,
  deleteLicense,
  disableLicense,
  updateCompanyInfo,
} = licensesSlise.actions;

export default licensesSlise.reducer;
