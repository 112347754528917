import $api from "../axios";
import { handleResponse } from "../../helpers/httpHelpers";

export const holderAPI = {
  getAll,
  get,
  update,
  getByCompanyName,
};

async function getAll() {
  try {
    return await $api.get(`/Holder`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function get(id) {
  try {
    return await $api.get(`/holder/getById?id=${id}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getByCompanyName(name) {
  try {
    return await $api.get(`/holder/getByCompanyName?name=${name}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function update(info) {
  try {
    return await $api.put(`/holder/update`, info);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

