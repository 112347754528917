import $api from "../axios";
import { handleResponse } from "../../helpers/httpHelpers";

export const historyAPI = {
  get,
  getAll,
  getByHolderId
};

async function get(model, serialNumber) {
  try {
    return await $api.get(`/history/getByScanner?model=${model}&serialNumber=${serialNumber}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getByHolderId(id) {
  try {
    return await $api.get(`/history/getByHolder?holderId=${id}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getAll() {
  try {
    return await $api.get(`/History`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}