import * as yup from "yup";

const productSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .max(60)
    .matches(
      /^((?!\s{2,}).)*$/,
      "Имя не должно содержать более одного пробела подряд"
    ),
  models: yup.array().of(yup.string()).required().min(1),
  description: yup.string(),
});

export default productSchema;
