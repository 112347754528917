import { useEffect, useState } from "react";
import { notifySuccess, notifyResponseError, notifyError } from "../helpers/notificationHelper";
import { productAPI } from "../services/api/productAPI";

const useProducts = () => {
  const [products, setProducts] = useState([]);
  const [productNames, setProductNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const setState = (newArray) => {
    setProducts(newArray);
    setProductNames([...new Set(newArray.map((item) => item.name))]);
  }

  useEffect(async () => {
    if (products.length === 0) {
      setIsLoading(true);
      try {
        const response = await productAPI.getAll();
        if (response.status === 200) {
          setState(response.data.filter(product => !product.disabled).reverse());
        } else {
          notifyResponseError(response, "Failed to load products");
        }
      } catch (error) {
        notifyError(error, "Failed to load products");
      }
      setIsLoading(false);
    }
  }, []);

  const addProduct = async (product) => {
    let result = false;
    setIsLoading(true);
    try{
      let response = await productAPI.create(product);
      if (response.status === 200) {
        notifySuccess("Product is created");
        let response = await productAPI.getByName(product.name);
        if (response.status === 200) {
          setState([response.data, ...products]);
          result = true;
        } else {
          notifyResponseError(response, "Failed to get product");
        }
      } else {
        notifyResponseError(response, "Failed to create product");
      }
    }catch(error){
      notifyError(error, "Failed to create product");
    }
    setIsLoading(false);
    return result;
  };

  const updateProduct = async (product) => {
    let result = false;
    setIsLoading(true);
    try{
      let response = await productAPI.update(product);
      if (response.status === 200) {
        notifySuccess("Product is updated");
        let updatedArray = products.map(item => (item.id === product.id ? product : item));
        setState(updatedArray);
        result = true;
      } else {
        notifyResponseError(response, "Failed to update product");
      }
    }catch(error){
      notifyError(error, "Failed to update product");
    }
    setIsLoading(false);
    return result;
  };

  const removeProduct = async (id) => {
    setIsLoading(true);
    try{
      let response = await productAPI.disable(id);
      if (response.status === 200) {
        notifySuccess("Product is removed");
        let result = products.filter((p) => p.id !== id);
        setProducts(result);
        setProductNames([...new Set(result.map((item) => item.name))]);
      } else {
        notifyResponseError(response, "Failed to remove product");
      }
    }catch(error){
      notifyError(error, "Failed to remove product");
    }
    setIsLoading(false);
  };

  return { products, productNames, isLoading, addProduct, updateProduct, removeProduct };
};

export default useProducts;
