export const handleResponse = (response) => {
  if (!response) {
    return {
      statusText: "Service Unavailable",
      status: 501,
      data: { status: 501 },
    };
  }
  if (response.status === 401) {
    localStorage.removeItem("user");
  }
  if (response.status === 403) {
    window.location.replace("/forbidden");
  }
  return response;
};
