export const AppStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  flexibleContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
};

export const HomePageStyles = {
  mainContainer: {
    marginBottom: "63px",
    height: "100%", // Use 100% height to adapt to parent container
  },
  sideBarContainer: {
    borderRight: "1px solid black",
    padding: "15px",
    width: "245px",
    display: "flex",
    flexDirection: "column",
  },
  addActionBox: {
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center",
  },
  exportActionBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  exportLabelBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  exportLabelText: {
    color: "gray",
    margin: "0px 5px",
    fontSize: "12px",
  },
  horizontalContainer: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  tableContainer: {
    flex: 1,
    padding: "25px 25px 0px 25px",
  },
  middleElement: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden", // Ensure no overflow for the whole cell content
  },
};

export const LicenseTableFilterBoxStyles = {
  container: {
    height: "100%",
  },
  searchBarBox: {
    marginBottom: "20px",
  },
  filtersHeaderBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clearButton: {
    width: "90px",
    height: "25px",
    textTransform: "none",
    color: "grey",
    borderColor: "grey",
  },
  timePeriodBox: {
    marginBottom: "20px",
  },
  timePeriodBoxTitle: {
    margin: "10px 0px",
  },
};
