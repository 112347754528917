import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import ArchivePage from "../pages/ArchivePage";
import LoginPage from "../pages/LoginPage";
import NotFound from "../pages/NotFound";
import RegistrationPage from "../pages/RegistrationPage";
import ProductManagement from "../pages/ProductManagement";
import UserManagement from "../pages/UserManagement";
import RegistrationConfirmation from "../components/RegistrationConfirmation";
import HistoryPage from "../pages/HistoryPage";
import HolderPage from "../pages/HolderPage";
import ProfilePage from "../pages/ProfilePage";
import DevicesPage from "../pages/DevicesPage";
import PasswordRecoveryPage from "../pages/PasswordRecoveryPage";
import ResetPasswordConfirmation from "../components/ResetPasswordConfirmation";
import Forbidden from "../pages/Forbidden";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/archive" element={<ArchivePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegistrationPage />} />
      <Route path="/register-confirmed" element={<RegistrationConfirmation />} />
      <Route path="/not-found-404" element={<NotFound />} />
      <Route path="/product-management" element={<ProductManagement />} />
      <Route path="/user-management" element={<UserManagement />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/holder/:id" element={<HolderPage />} />
      <Route path="/history/:model/:serialNumber" element={<HistoryPage />} />
      <Route path="/devices" element={<DevicesPage />} />
      <Route path="/forgot-password" element={<PasswordRecoveryPage />} />
      <Route path="/reset-password-confirmation" element={<ResetPasswordConfirmation/>} />
      <Route path="/forbidden" element={<Forbidden />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
