import { Link as RouterLink } from "react-router-dom";
import { Container, Grid, TextField, Button, Typography, Link } from "@mui/material";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(4).required(),
});

function LoginForm(props) {
  const {control, handleSubmit, formState: { errors }} = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Container maxWidth="xs">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" margin="40px 0 20px 0">
            Login
          </Typography>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.email?.message)}
                  fullWidth={true}
                  type="email"
                  label="Email"
                  variant="filled"
                  helperText={errors.email?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              autoComplete="off"
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.password?.message)}
                  type="password"
                  fullWidth={true}
                  label="Password"
                  variant="filled"
                  helperText={errors.password?.message}
                />
              )}
            />
            <Link underline="always" href="/forgot-password" variant="caption">
              Forgot password
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={props.isLoading}
                >
                  Login
                </Button>
              </Grid>
              <Grid item>
                <Button color="inherit" component={RouterLink} to="/register">
                  Create an account
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default LoginForm;
