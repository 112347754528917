import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  Autocomplete,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import Loader from "../Loader/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import productSchema from "../../validationSchemas/productSchema";
import NotNullFieldFootnote from "../../components/NotNullFieldFootnote";

function CreateProductDialog({ children, addProduct, isLoading, devices, deviceTypes }) {
  const [open, setOpen] = useState(false);
  const [deviceModels, setDeviceModels] = useState([]);
  const [productModels, setProductModels] = useState([]);

  const {
    reset,
    resetField,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(productSchema),
  });

  const onSubmit = async (data) => {
    let isAdded = await addProduct({
      name: data.name,
      models: data.models,
      description: data.description,
    });
    isAdded && handleClose();
  };

  const onTypeChange = async (data) => {
    data === null
      ? setDeviceModels([])
      : setDeviceModels([...new Set(devices.filter(item => item.type === data).map((item) => item.model))]);
  };

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    reset({
      description: "",
      models: [],
      name: "",
      scannerModel: "",
      type: "",
    });
    setProductModels([]);
    setOpen(false);
  };

  const handleAddModel = (data) => {
    if (
      !productModels.includes(data.scannerModel) &&
      Boolean(data.scannerModel)
    ) {
      let models = [data.scannerModel, ...productModels];
      setProductModels(models);
      reset({ ...getValues(), models });
    }
  };

  return (
    <>
      <Button variant="contained" onClick={handleClickOpen}>
        {children}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle margin="20px 20px 0 20px">Add new product</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid item margin="0px 20px">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="product-name"
                      style={{ marginBottom: "8px" }}
                    >
                      Product name *
                    </InputLabel>
                    <Controller
                      name="name"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          id="product-name"
                          error={Boolean(errors.name?.message)}
                          fullWidth={true}
                          type="text"
                          variant="outlined"
                          helperText={errors.name?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="models"
                      style={{ marginBottom: "8px" }}
                    >
                      Available for: *
                    </InputLabel>
                    <Controller
                      name="models"
                      control={control}
                      render={({ field }) => (
                        <>
                          <div
                            style={{
                              height: 64,
                              overflow: "auto",
                              border: "1px solid #E0E0E0",
                              borderRadius: "4px",
                              padding: "8px",
                            }}
                          >
                            <List
                              id="models"
                              style={{ padding: "0" }}
                              defaultValue={productModels}
                            >
                              {productModels.map((model, index) => (
                                <ListItem key={index} style={{ padding: "0" }}>
                                  <ListItemText primary={model} />
                                  <IconButton
                                    style={{ padding: "0" }}
                                    onClick={() => {
                                      let models = productModels.filter(
                                        (_, i) => i !== index
                                      );
                                      field.onChange(models);
                                      setProductModels(models);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItem>
                              ))}
                            </List>
                          </div>
                          <Typography
                            color="error"
                            variant="body2"
                            style={{
                              margin: errors.models?.message
                                ? "0 0 -20px 0"
                                : "0 0 0 0",
                            }}
                          >
                            {errors.models?.message}
                          </Typography>
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel style={{ marginBottom: "8px" }}>
                      Add scanner:
                    </InputLabel>
                    <Controller
                      name="type"
                      control={control}
                      render={(param) => (
                        <Autocomplete
                          {...param}
                          options={deviceTypes}
                          getOptionLabel={(option) => option}
                          onChange={(_, type) => {
                            param.field.onChange(type);
                            onTypeChange(type);
                            resetField("scannerModel");
                          }}
                          renderInput={(field) => (
                            <TextField
                              {...field}
                              label="Scanner Type"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ padding: "12px 0 0 24px" }}>
                    <Controller
                      key={deviceModels}
                      name="scannerModel"
                      control={control}
                      render={(param) => (
                        <Autocomplete
                          {...param}
                          key={deviceModels}
                          options={deviceModels}
                          getOptionLabel={(option) => option}
                          onChange={(_, model) => {
                            param.field.onChange(model);
                          }}
                          renderInput={(field) => (
                            <TextField
                              {...field}
                              label="Scanner Model"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ padding: "12px 0 0 24px" }}>
                    <Button
                      style={{ width: "71px" }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddModel(getValues())}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="description"
                      style={{ marginBottom: "8px" }}
                    >
                      Product description
                    </InputLabel>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline
                          id="description"
                          fullWidth={true}
                          rows={3}
                        />
                      )}
                    />
                  </Grid>
                  <NotNullFieldFootnote/>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              spacing={3}
              justifyContent="flex-end"
              margin="0px 40px 20px 40px"
              alignItems="center"
            >
              <Grid item style={{ padding: "0" }}>
                <Button
                  autoFocus
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                  style={{ width: "91px" }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item style={{ padding: "0px 0px 0px 10px" }}>
                <Button
                  style={{ width: "91px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
        {isLoading && <Loader />}
      </Dialog>
    </>
  );
}

export default CreateProductDialog;
