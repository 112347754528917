import { Container } from "@mui/material";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import ChangePasswordForm from "../components/ChangePasswordForm";
import { userAPI } from "../services/api/userAPI";
import { useAuth } from "../hooks/useAuth";
import ProfileInfo from "../components/ProfileInfo";
import Loader from "../components/Loader/Loader";
import { notifyResponseError, notifySuccess } from "../helpers/notificationHelper";

function ProfilePage() {
  const { isAuth, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const onChangePassword = async (data) => {
    setIsLoading(true);
    let response = await userAPI.changePassword(
      data.currentPassword,
      data.newPassword
    );
    if (response.status === 200) {
      localStorage.setItem("user", JSON.stringify(response.data));
      notifySuccess("Password is updated");
    } else {
      notifyResponseError(response, "Failed to change password");
    }
    setIsLoading(false);
  };

  if(!isAuth){
    return <Navigate to="/login" />
  }

  return (
    <Container maxWidth={false} style={{ padding: "0px 35px 85px 35px " }}>
      <title>Profile</title>
      <div style={{width: "600px"}}>
        <ProfileInfo {...user}/>
        <ChangePasswordForm
          login={user.email}
          isLoading={isLoading}
          onSubmit={onChangePassword}
        />
      </div>
      {isLoading && <Loader />}
    </Container>
  );
}

export default ProfilePage;
