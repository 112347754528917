import {
    Button,
    Grid,
    InputLabel,
    TextField,
    Typography,
  } from "@mui/material";
  import { useEffect, useState } from "react";
  import { notifyResponseError, notifyInfo, notifySuccess } from "../helpers/notificationHelper";
  import { Controller, useForm } from "react-hook-form";
  import { yupResolver } from "@hookform/resolvers/yup";
  import holderSchema from "../validationSchemas/holderSchema";
  import { holderAPI } from "../services/api/holderAPI";
  
  const labelStyle = {
    fontSize: "14px",
    marginTop: "10px"
  };
  
  function HolderInfoForm({id}) {
    const [isLoading, setIsLoading] = useState(false);
    const [initialData, setInitialData] = useState({});
    const _ = require('lodash');
  
    const {
      reset,
      getValues,
      control,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(holderSchema),
    });
  
    useEffect(async () => {
      id && loadHolderInfo();
    }, [id]);
  
    const loadHolderInfo = async () => {
        setIsLoading(true);
      const response = await holderAPI.get(id);
      if (response.status === 200) {
        reset({ 
          companyName : response.data.companyName,
          email : response.data.email,
          phone : response.data.phoneNumber,
          name : response.data.contactPerson,
          comment: response.data.comments
         });
         setInitialData(getValues());
      } else {
        notifyResponseError(response, "Failed to load holder info");
      }
      setIsLoading(false);
    };
  
    const onSubmit = async (data) => {
      const isEqual = _.isEqual(initialData, data);
      if (isEqual) {
        notifyInfo("Nothing to save");
      } else {
        setIsLoading(true);
        const holderInfo = {
          id: id,
          companyName: data.companyName,
          contactPerson: data.name,
          phoneNumber: data.phone,
          email: data.email,
          comments: data.comment,
        };
        let response = await holderAPI.update(holderInfo);
        if (response.status === 200) {
          setInitialData(getValues());
          notifySuccess("Holder info is updated");
        }
        setIsLoading(false);
      }
    };
  
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item>
              <Typography variant="h6" margin="5px 0">
                License holder info
              </Typography>
              <InputLabel htmlFor="company-Name" style={labelStyle}>
                Company name
              </InputLabel>
              <Controller
                name="companyName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="company-Name"
                    error={Boolean(errors.companyName?.message)}
                    type="text"
                    fullWidth={true}
                    variant="outlined"
                    helperText={errors.companyName?.message}
                    InputProps={{
                      style: { height: "30px" },
                    }}
                  />
                )}
              />
              <InputLabel htmlFor="contact-person-name" style={labelStyle}>
                Contact person
              </InputLabel>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="contact-person-name"
                    error={Boolean(errors.name?.message)}
                    type="text"
                    fullWidth={true}
                    variant="outlined"
                    helperText={errors.name?.message}
                    InputProps={{
                      style: { height: "30px" },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item marginTop={"37px"}>
              <InputLabel htmlFor="email" style={labelStyle}>
                Email
              </InputLabel>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="email"
                    error={Boolean(errors.email?.message)}
                    type="text"
                    fullWidth={true}
                    variant="outlined"
                    helperText={errors.email?.message}
                    InputProps={{
                      style: { height: "30px" },
                    }}
                  />
                )}
              />
              <InputLabel htmlFor="phone" style={labelStyle}>
                Phone
              </InputLabel>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="phone"
                    error={Boolean(errors.phone?.message)}
                    type="text"
                    fullWidth={true}
                    variant="outlined"
                    helperText={errors.phone?.message}
                    InputProps={{
                      style: { height: "30px" },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item marginTop={"37px"} width={"250px"}>
              <InputLabel htmlFor="comment" style={labelStyle}>
                Comment
              </InputLabel>
              <Controller
                name="comment"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="comment"
                    error={Boolean(errors.comment?.message)}
                    type="text"
                    multiline
                    rows={3}
                    fullWidth={true}
                    variant="outlined"
                    helperText={errors.comment?.message}
                  />
                )}
              />
              <Grid container justifyContent="flex-end" marginTop={"15px"}>
                <Button
                  style={{ width: "91px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
    );
  }
  
  export default HolderInfoForm;
  