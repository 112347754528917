import $api from "../axios";
import { handleResponse } from "../../helpers/httpHelpers";

export const deviceAPI = {
  get,
  create,
  getAll
};

async function get(params) {
  try {
    return await $api.get(`/device/get`, {params});
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function create(data) {
  try {
    return await $api.post("/device/create", data);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getAll() {
  try {
    return await $api.get(`/Device`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}
