import {
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "../hooks/useAuth";
import { Navigate } from "react-router-dom";
import { withAuth } from "../hocs/withAuth";
import useProducts from "../hooks/useProducts";
import Loader from "../components/Loader/Loader";
import ProductRow from "../components/ProductRow";
import StyledHeadCell from "../components/Styled/StyledHeadCell";
import CreateProductDialog from "../components/Dialogs/CreateProductDialog";
import useDeviceData from "../hooks/useDeviceData";

function ProductManagement(props) {
  const { isAuth } = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { products, addProduct, updateProduct, removeProduct, isLoading } = useProducts();
  const { permissions } = useSelector((state) => state.permissions);
  const { devices, deviceTypes  } = useDeviceData();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  if (permissions && !permissions?.editProduct) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Container maxWidth={false} style={{ padding: "0px 35px 65px 35px " }}>
      <title>Product management</title>
      <h2>Product Management</h2>
      <CreateProductDialog
        addProduct={addProduct}
        isLoading={isLoading}
        devices={devices}
        deviceTypes={deviceTypes}
      >
        Add new product
      </CreateProductDialog>
      <Typography variant="h6" margin="20px 0">
        Available products
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHeadCell>Product name</StyledHeadCell>
              <StyledHeadCell>Scanner models</StyledHeadCell>
              <StyledHeadCell>Product description</StyledHeadCell>
              <StyledHeadCell align="center" style={{ width: "40px" }}>
                Edit
              </StyledHeadCell>
              <StyledHeadCell align="center" style={{ width: "40px" }}>
                Delete
              </StyledHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((product, index) => (
                <ProductRow
                  devices={devices}
                  deviceTypes={deviceTypes}
                  index={index}
                  product={product}
                  isLoading={isLoading}
                  updateProduct={updateProduct}
                  removeProduct={removeProduct}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={products?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {isLoading && <Loader />}
    </Container>
  );
}

export default withAuth(ProductManagement);
