import { Navigate } from "react-router-dom";
import {
  notifyResponseError,
  notifySuccess,
} from "../helpers/notificationHelper";
import LicenseTable from "../components/LicenseTable";
import Loader from "../components/Loader/Loader";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { licenseAPI } from "../services/api/licenseAPI";
import { useAuth } from "../hooks/useAuth";
import { withAuth } from "../hocs/withAuth";
import CreateLicenseDialog from "../components/Dialogs/CreateLicenseDialog/CreateLicenseDialog";
import ExcelExportHelper from "../components/ExcelExportHelper";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LicenseTableFilterBox from "../components/LicenseTableFilterBox";
import {HomePageStyles as s} from "../styles/main";

function HomePage(props) {
  const { permissions } = useSelector((state) => state.permissions);
  const { isAuth, user } = useAuth();
  const [licenses, setLicenses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    page: localStorage.getItem("page") ? parseInt(localStorage.getItem("page")) : 0,
    pageSize: localStorage.getItem("rowsPerPage") ? parseInt(localStorage.getItem("rowsPerPage")) : 20,
    scannerType: JSON.parse(localStorage.getItem("selectedTypes")) ?? [],
    scannerCategory: JSON.parse(localStorage.getItem("selectedCategories")) ?? [],
    startDate: localStorage.getItem("from") ? new Date(localStorage.getItem("from")) : null,
    endDate: localStorage.getItem("to") ? new Date(localStorage.getItem("to")) : null,
    search: localStorage.getItem("searchText") ?? "",
    searchType: 1
  });
  const [totalCount, setTotalCount] = useState(0);

  useEffect(async () => {
    if (!isAuth || !permissions) return;
    await loadLicenses(filter);
  }, [isAuth, permissions, filter]);

  const loadLicenses = async (filter) => {
    if (!isAuth || !permissions) return;
    setIsLoading(true);
    let response = await licenseAPI.getFiltered({
      ...filter,
      createdBy: permissions?.seeAllCustomers ? null : user.id,
    });
    if (response.status === 200) {
      setTotalCount(response.data.totalItems);
      setLicenses(response.data.licenses);
    } else {
      notifyResponseError(response, "Failed to load licenses");
    }
    setIsLoading(false);
  };

  const getWholeData = async () => {
    if (!isAuth || !permissions) return;
    setIsLoading(true);
    let response = await licenseAPI.getFiltered({
      ...filter,
      page: null,
      pageSize: null,
      createdBy: permissions?.seeAllCustomers ? null : user.id,
    });
    setIsLoading(false);
    if (response.status !== 200) {
      notifyResponseError(response, "Failed to load licenses");
    }
    return response?.data?.licenses;
  };

  const handleDisableLicense = async (id) => {
    setIsLoading(true);
    let response = await licenseAPI.moveToArchive(id);
    if (response.status === 200) {
      await loadLicenses(filter);
      notifySuccess("License is moved to archive");
    } else {
      notifyResponseError(response, "Failed to archive license");
    }
    setIsLoading(false);
  };

  const download = async (id) => {
    setIsLoading(true);
    let response = await licenseAPI.download(id);
    if (response.status === 200) {
      const contentDisposition = response.headers["content-disposition"];
      const content = contentDisposition.split(";");
      const index = content.findIndex((el) => el.includes(" filename="));
      if (index !== -1) {
        const filename = content[index].replace(" filename=", "");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`);
        document.body.appendChild(link);
        link.click();
      }
    } else {
      notifyResponseError(response, "Failed to download license");
    }
    setIsLoading(false);
  };

  const onPageChanged = (_, value) => {
    localStorage.setItem("page", value);
    setFilter({...filter, page : value});
  };

  const handleChangeRowsPerPage = (event) => {
    let value = parseInt(event.target.value, 10);
    localStorage.setItem("rowsPerPage", value);
    setFilter({...filter, pageSize : value});
  };

  const applyFilters = (categories, types, from, to, searched) => {
    localStorage.setItem("page", 0);
    setFilter({...filter,
      page: 0,
      scannerType: types,
      scannerCategory: categories,
      search: searched,
      startDate: from,
      endDate: to
    });
  };

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <title>License management</title>
      <div style={s.mainContainer}>
        <div style={s.horizontalContainer}>
          <div style={s.sideBarContainer}>
            <div style={s.addActionBox}>
              {permissions?.addLicense && (
                <CreateLicenseDialog width="235px" callback={() => loadLicenses(filter)}>
                  Add license
                </CreateLicenseDialog>
              )}
            </div>
            <LicenseTableFilterBox isLoading={isLoading} onChange={applyFilters} data={filter}/>
            <div style={s.exportActionBox}>
              <div style={s.exportLabelBox}>
                <InfoOutlinedIcon fontSize="small" color="action" />
                <small style={s.exportLabelText}>
                  Export takes into account applied filters
                </small>
              </div>
              <ExcelExportHelper width="235px" getData={getWholeData} />
            </div>
          </div>
          <div style={s.tableContainer}>
            <LicenseTable
              isLoading={isLoading}
              collection={licenses}
              onDisable={handleDisableLicense}
              onDownload={download}
              totalCount={totalCount}
              page={filter.page}
              rowsPerPage={filter.pageSize}
              onPageChanged={onPageChanged}
              onRowsPerPageChenged={handleChangeRowsPerPage}
              canAddLicense={permissions?.addLicense}
              canEditHolderInfo={permissions?.editHolderInfo}
              canEditCompanyInfo={permissions?.editCompanyInfo}
              canDownload={permissions?.downloadLicense}
            />
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    </>
  );
}

export default withAuth(HomePage);
