import * as yup from "yup";

const deviceSchema = yup.object().shape({
  group: yup
    .string()
    .required()
    .nullable()
    .max(50)
    .matches(
      /^[a-zA-ZА-Яа-я0-9 №()"«»'.,:;\/]+$/,
      "Category must not contain characters other than hyphens, spaces, and № « » . , / ; : ( )"
    ),
  type: yup
    .string()
    .required()
    .nullable()
    .max(50)
    .matches(
      /^[a-zA-ZА-Яа-я0-9 №()"«»'.,:;\/]+$/,
      "Type must not contain characters other than hyphens, spaces, and № « » . , / ; : ( )"
    ),
  model: yup
    .string()
    .required()
    .nullable()
    .max(50)
    .matches(
      /^[a-zA-ZА-Яа-я0-9 №()"«»'.,:;\/]+$/,
      "Model must not contain characters other than hyphens, spaces, and № « » . , / ; : ( )"
    ),
  serialNumber: yup
    .string()
    .required()
    .max(10)
    .matches(
      /^[a-zA-Z0-9]+$/,
      "Serial number must contain only alphanumeric characters"
    ),
});

export default deviceSchema;
