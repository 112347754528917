import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifyResponseError } from "../helpers/notificationHelper";
import Loader from "../components/Loader/Loader";
import { useAuth } from "../hooks/useAuth";
import { userAPI } from "../services/api/userAPI";

function Forbidden() {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const updateRights = async () => {
    setIsLoading(true);
    let response = await userAPI.get(user.id);
    if (response.status === 200) {
      localStorage.setItem("user", JSON.stringify({...response.data, token: user.token}));
      navigate("/");
    } else {
      notifyResponseError(response, "Failed to get user");
    }
    setIsLoading(false);
  };

  return (
    <>
      <Container maxWidth="xl">
        <title>Forbidden</title>
        <h1>Action is forbidden</h1>
        <Typography variant="h5">
          You no longer have the rights to this operation.
        </Typography>
        <Typography variant="h5">
          Сontact the support service for more information.
        </Typography>
        <Box pt={3}>
          <Button variant="contained" onClick={updateRights}>
            Ok
          </Button>
        </Box>
      </Container>
      {isLoading && <Loader />}
    </>
  );
}
export default Forbidden;
