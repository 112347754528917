import { Container } from "@mui/material";

function NotFound() {
  return (
    <Container maxWidth="xl">
      <title>Not found</title>
      <h1>404</h1>
      <h2>Page not found.</h2>
    </Container>
  );
};

export default NotFound;
