import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { companyAPI } from "../../services/api/companyAPI";
import { notifyResponseError, notifySuccess } from "../../helpers/notificationHelper";
import { Grid, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, InputLabel, Box, Tooltip } from "@mui/material";
import Loader from "../Loader/Loader";
import NotNullFieldFootnote from "../NotNullFieldFootnote";
import { useDispatch } from "react-redux";
import { updateCompanyInfo } from "../../store/slices/licensesSlise";

function EditCompanyDialog(props) {
  const {
    withTooltip,
    tooltipTitle,
    companyName,
    children,
  } = props;

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    name: yup.string().required(),
    address: yup.string(),
    comments: yup.string(),
  });
  
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loadCompany = async () => {
    setIsLoading(true);
    let response = await companyAPI.getByName(companyName);
    if (response.status === 200) {
      const preloads = getObjectWithoutNullProps(response.data);
      reset({ ...preloads });
      setOpen(true);
    } else {
      handleClose();
      notifyResponseError(response, "Failed to load company");
    }
    setIsLoading(false);
  };

  const getObjectWithoutNullProps = (obj) => {
    const notNullData = new Object();
    Object.entries(obj)
      .filter(([name, value]) => value !== null)
      .map(([name, value]) => ({name, value}))
      .forEach((el) => notNullData[el.name] = el.value);
    return notNullData;
  }

  const onSubmit = async (data) => {
    setIsLoading(true);
    let response = await companyAPI.update(data);
    if (response.status === 200) {
      notifySuccess("Company is updated");
      if(companyName != data.name)
        dispatch(updateCompanyInfo({companyName, newValue: data.name}));
    } else {
      notifyResponseError(response, "Failed to update company");
    }
    setIsLoading(false);
    handleClose();
  };

  const handleClickOpen = async () => {
    await loadCompany();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const content = (
    <Box
      component="span"
      sx={{
        textDecoration: "none",
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline",
        },
      }}
      onClick={handleClickOpen}
    >
      {children}
    </Box>
  );

  return (
    <>
      {withTooltip ? (
        <Tooltip title={tooltipTitle}>{content}</Tooltip>
      ) : (
        content
      )}
      {isLoading && <Loader />}
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle margin="20px">
            Editing License issuer information
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid item margin="0px 20px">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="company-name"
                      style={{ marginBottom: "8px" }}
                    >
                      License issuer *
                    </InputLabel>
                    <Controller
                      name="name"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          id="company-name"
                          error={Boolean(errors.name?.message)}
                          fullWidth={true}
                          type="text"
                          variant="outlined"
                          helperText={errors.name?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="company-address"
                      style={{ marginBottom: "8px" }}
                    >
                      Address
                    </InputLabel>
                    <Controller
                      name="address"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth={true}
                          id="company-address"
                          type="text"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="comments"
                      style={{ marginBottom: "8px" }}
                    >
                      Comments
                    </InputLabel>
                    <Controller
                      name="comments"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline
                          id="comments"
                          fullWidth={true}
                          rows={6}
                        />
                      )}
                    />
                  </Grid>
                  <NotNullFieldFootnote />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              spacing={3}
              justifyContent="flex-end"
              margin="0px 40px 20px 40px"
              alignItems="center"
            >
              <Grid item style={{ padding: "0" }}>
                <Button
                  autoFocus
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                  style={{ width: "91px" }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item style={{ padding: "0px 0px 0px 10px" }}>
                <Button
                  style={{ width: "91px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default EditCompanyDialog;
