import { Navigate } from "react-router-dom";
import {
  notifyResponseError,
  notifySuccess,
} from "../helpers/notificationHelper";
import ArchiveTable from "../components/ArchiveTable";
import Loader from "../components/Loader/Loader";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { licenseAPI } from "../services/api/licenseAPI";
import { useAuth } from "../hooks/useAuth";
import { withAuth } from "../hocs/withAuth";
import ArchiveTableFilterBox from "../components/ArchiveTableFilterBox";
import { HomePageStyles as s } from "../styles/main";

function ArchivePage(props) {
  const { permissions } = useSelector((state) => state.permissions);
  const { isAuth, user } = useAuth();
  const [licenses, setLicenses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    page: localStorage.getItem("a_page") ? parseInt(localStorage.getItem("a_page")) : 0,
    pageSize: localStorage.getItem("a_rowsPerPage") ? parseInt(localStorage.getItem("a_rowsPerPage")) : 20,
    scannerType: JSON.parse(localStorage.getItem("a_selectedTypes")) ?? [],
    scannerCategory: JSON.parse(localStorage.getItem("sa_electedCategories")) ?? [],
    startDate: localStorage.getItem("a_from") ? new Date(localStorage.getItem("a_from")) : null,
    endDate: localStorage.getItem("a_to") ? new Date(localStorage.getItem("a_to")) : null,
    search: localStorage.getItem("a_searchText") ?? "",
    searchType: 2
  });
  const [totalCount, setTotalCount] = useState(0);

  useEffect(async () => {
    if (!isAuth || !permissions) return;
    await loadLicenses(filter);
  }, [isAuth, permissions, filter]);

  const loadLicenses = async (filter) => {
    if (!isAuth || !permissions) return;
    setIsLoading(true);
    let response = await licenseAPI.getFiltered({
      ...filter,
      createdBy: permissions?.seeAllCustomers ? null : user.id,
    });
    if (response.status === 200) {
      setTotalCount(response.data.totalItems);
      setLicenses(response.data.licenses);
    } else {
      notifyResponseError(response, "Failed to load licenses");
    }
    setIsLoading(false);
  };

  const handleDeleteLicense = async (id) => {
    setIsLoading(true);
    let response = await licenseAPI.remove(id);
    if (response.status === 200) {
      await loadLicenses(filter);
      notifySuccess("License is deleted");
    } else {
      notifyResponseError(response, "Failed to delete the license");
    }
    setIsLoading(false);
  };

  const onPageChanged = (_, value) => {
    localStorage.setItem("a_page", value);
    setFilter({...filter, page : value});
  };

  const handleChangeRowsPerPage = (event) => {
    let value = parseInt(event.target.value, 10);
    localStorage.setItem("a_rowsPerPage", value);
    setFilter({...filter, pageSize : value});
  };

  const applyFilters = (categories, types, from, to, searched) => {
    localStorage.setItem("a_page", 0);
    setFilter({...filter,
      page: 0,
      scannerType: types,
      scannerCategory: categories,
      search: searched,
      startDate: from,
      endDate: to
    });
  };

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <title>License management</title>
      <div style={s.mainContainer}>
        <div style={s.horizontalContainer}>
          <div style={s.sideBarContainer}>
            <ArchiveTableFilterBox isLoading={isLoading} onChange={applyFilters} data={filter}/>
          </div>
          <div style={s.tableContainer}>
            <ArchiveTable
              totalCount={totalCount}
              page={filter.page}
              rowsPerPage={filter.pageSize}
              onPageChanged={onPageChanged}
              onRowsPerPageChenged={handleChangeRowsPerPage}
              isLoading={isLoading}
              collection={licenses}
              onDelete={handleDeleteLicense}
              canEditHolderInfo={permissions?.editHolderInfo}
              canDeleteProduct={permissions?.workWithArchive}
            />
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    </>
  );
}

export default withAuth(ArchivePage);
