import { Box, Button, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function HeaderItem(props) {
    const { to, children } = props;
    const [isCurrent, setIsCurrent] = useState(false);
    const location = useLocation();
  
    useEffect(() => {
      const pathAfterFirstSlash = location.pathname.substring(location.pathname.indexOf('/'));
      setIsCurrent(pathAfterFirstSlash == to);
    }, [location.pathname]);
  
    return (
      <Button color="inherit" component={Link} to={to}>
        {isCurrent ? (
          <Box display="inline-block" position="relative">
            {children}
            <Divider
              orientation="horizontal"
              absolute
              style={{
                position: "absolute",
                bottom: "0",
                width: "100%",
                background: "white",
                height: "1px",
              }}
            />
          </Box>
        ) : (
          <>{children}</>
        )}
      </Button>
    );
  }

  export default HeaderItem;