import { useState } from "react";
import RegistrationForm from "../components/RegistrationForm";
import { authAPI } from "../services/api/authAPI";
import { notifyResponseError } from "../helpers/notificationHelper";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";

function RegistrationPage() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    let response = await authAPI.register(data);
    if (response.status === 200) {
      navigate("/register-confirmed");
    } else {
      notifyResponseError(response, "Failed to register");
    }
    setIsLoading(false);
  };

  return (
    <div>
      <title>Registration</title>
      <RegistrationForm onSubmit={onSubmit} isLoading={isLoading} />
      {isLoading && <Loader />}
    </div>
  );
}

export default RegistrationPage;
