import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import UsersTable from "../components/UsersTable";
import { userAPI } from "../services/api/userAPI";
import { notifyResponseError, notifySuccess } from "../helpers/notificationHelper";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/material";
import { setUsers } from "../store/slices/usersSlise";
import { useAuth } from "../hooks/useAuth";
import Loader from "../components/Loader/Loader";
import { withAuth } from "../hocs/withAuth";
import { toggleActivate, updatePermissions } from "../store/slices/usersSlise";

function UserManagement(props) {
  const { permissions } = useSelector((state) => state.permissions);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { isAuth, user } = useAuth();
  const { users } = useSelector((state) => state.users);

  const saveChanges = async (id) => {
    setIsLoading(true);
    const user = users.find((element) => element.id === id);
    if (user) {
      let response = await userAPI.update(user);
      response.status === 200
      ? notifySuccess('User is updated')
      : notifyResponseError(response, "Failed to update user");
    }
    setIsLoading(false);
    await loadUsers();
  };

  const deleteUser = async (id) => {
    setIsLoading(true);
    let response = await userAPI.disable(id);
    response.status === 200
      ? notifySuccess('User is deleted')
      : notifyResponseError(response, "Failed to delete user");
    setIsLoading(false);
    await loadUsers();
  };

  const deactivateUser = async (id) => {
    setIsLoading(true);
    let response = await userAPI.deactivate(id);
    if (response.status === 200) {
      notifySuccess("User is deactivated");
      handleActivateProperty(id);
    } else notifyResponseError(response, "Failed to deactivate user");
    setIsLoading(false);
  };

  const activateUser = async (id) => {
    setIsLoading(true);
    let response = await userAPI.activate(id);
    if (response.status === 200) {
      notifySuccess("User is activated");
      handleActivateProperty(id);
    } else notifyResponseError(response, "Failed to activate user");
    setIsLoading(false);
  };

  const handleActivateProperty = (id) => {
    const user = users.find((element) => element.id === id);
    if (user.isActive) {
      let permissions = {...user.permissions};
      // сбрасываем все включенные разрешения, если пользователь был активен и имел какие-либо разрешения,
      // так как неактивный пользователь не может ничего
      for (let key in permissions) {
        permissions[key] = false;
      }
      dispatch(updatePermissions({id, permissions}));
    }
    dispatch(toggleActivate(id));
  };

  const loadUsers = async () => {
    setIsLoading(true);
    let response = await userAPI.getAll();
    if (response.status === 200) {
      const usersExeptSuperAdmin = response.data.filter((u) => {
        return !u.superAdmin;
      });
      let users = usersExeptSuperAdmin.map((obj) => ({
        ...obj,
        isEdited: false,
      }));
      dispatch(setUsers(users.reverse()));
    } else {
      notifyResponseError(response, "Failed to load users");
    }
    setIsLoading(false);
  };

  const changePermissions = async (id, permissions) => {
    setIsLoading(true);
    let response = await userAPI.updateUserPermissions(id, permissions);
    if (response.status === 200) {
      notifySuccess("User's permissions are updated");
      dispatch(updatePermissions({id, permissions}));
    } else notifyResponseError(response, "Failed to update user's permissions");
    setIsLoading(false);
  };

  const handleUserActivation = async (id, isActivate) => {
    isActivate ? await activateUser(id) : await deactivateUser(id);
  };

  useEffect(async () => {
    if (!isAuth || !permissions?.editUserInfo) return;
    await loadUsers();
  }, [isAuth, permissions]);

  if(!isAuth){
    return <Navigate to="/login" />
  }

  if(permissions && !permissions?.editUserInfo){
    return <Navigate to="/forbidden" />
  }

  return (
    <Container maxWidth={false} style={{ padding: "0px 35px 65px 35px " }}>
      <title>User management</title>
      <div>
        <UsersTable
          currentUserId={user?.id}
          users={users}
          saveChanges={saveChanges}
          deleteUser={deleteUser}
          changePermissions={changePermissions}
          handleUserActivation={handleUserActivation}
        />
      </div>
      {isLoading && <Loader />}
    </Container>
  );
}

export default withAuth(UserManagement);
