import React, { useState } from "react";
import { Button, Stepper, Step, StepLabel, Container, Dialog } from '@mui/material';
import {
  notifyResponseError,
  notifySuccess,
} from "../../../helpers/notificationHelper";
import CreateLicenseStepOne from './CreateLicenseStepOne';
import CreateLicenseStepTwo from './CreateLicenseStepTwo';
import CreateLicenseStepThree from './CreateLicenseStepThree';
import Loader from "../../Loader/Loader";
import { productAPI } from "../../../services/api/productAPI";
import { holderAPI } from "../../../services/api/holderAPI";
import { licenseAPI } from "../../../services/api/licenseAPI";
import useDeviceData from "../../../hooks/useDeviceData";

const styles = {
  stepTitle: {
    textAlign: 'center',
    marginY: 2,
  },
  stepSubtitle: {
    marginY: 3,
  },
};

function CreateLicenseDialog({ children, width, callback }) {
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [products, setProducts] = useState([]);
  const [holders, setHolders] = useState([]);

  const [holderInfoWasEdited, setHolderInfoWasEdited] = useState(false);
  const [scannerHasHolder, setScannerHasHolder] = useState(false);

  const { deviceTypes } = useDeviceData();
  const [models, setModels] = useState([]);
  const [scannerNumbers, setScannerNumbers] = useState([]);

  const [isScannerKeyRequered, setIsScannerKeyRequered] = useState(false);
  const [scannerKey, setScannerKey] = useState("");

  const [step1Data, setStep1Data] = useState({});
  const [step2Data, setStep2Data] = useState({});
  const [step3Data, setStep3Data] = useState({});

  const handleNext = async () => {
      setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const loadHolders = async () => {
    setIsLoading(true);
    let response = await holderAPI.getAll();
    if (response.status === 200) {
      const holdersResult = [...new Set(response.data.map((item) => item))];
      setHolders(holdersResult);
    } else {
      notifyResponseError(response, "Failed to load holders");
    }
    setIsLoading(false);
  };

  const onSubmit = async (lastData) => {
    setIsLoading(true);
    const createResponse = await licenseAPI.create({
      ...step1Data,
      holder: {
        companyName: step2Data.company,
        contactPerson: step2Data.contactPersonName,
        phoneNumber: step2Data.phoneNumber,
        comments: step2Data.detailes,
        email: step2Data.email
      },
      version: +lastData.generateOldVersion,
      validTill: lastData.validTill,
      product: lastData.product,
    });
    if (createResponse.status !== 200) {
      notifyResponseError(createResponse, "Failed to create license");
    } else {
      handleClose();
      await callback();
      notifySuccess("License is created");
    }
    setIsLoading(false);
  };

  const loadProductByModel = async (model) => {
    setIsLoading(true);
    let response = await productAPI.getAll();
    if (response.status === 200) {
      const productsResult = [...new Set(response.data.filter((item) => item.models && item.models.includes(model) && !item.disabled))];
      setProducts(productsResult);
      setStep3Data({...step3Data, product: null});
    } else {
      notifyResponseError(response, "Failed to load products");
    }
    setIsLoading(false);
  };

  const loadHolderIfLicenseAlreadyExists = async (model, serialNumber) => {
    setIsLoading(true);
    setStep2Data({});
    let response = await licenseAPI.getByScanner(model, serialNumber);
    if (response.status === 200 || response.status === 204) {
      if(!isHolderFound(response.data)){
        await loadHolders();
        setScannerHasHolder(false);
      }else{
        var holders = response.data.filter(x => Boolean(x.holderId));
        await loadHolderById(holders[0].holderId);
        setScannerHasHolder(true);
      }
    } else {
      notifyResponseError(response, "Failed to load holder info");
    }
    setIsLoading(false);
  };

  const isHolderFound = (data) => {
    if(!data) return false;
    if(data.length === 0) return false;
    var holders = data.filter(x => Boolean(x.holderId));
    if(!holders) return false;
    if(holders.length === 0) return false;
    return true;
  }

  const loadHolderById = async (id) => {
    let response = await holderAPI.get(id);
    if (response.status === 200) {
        setHolders([response.data]);
    } else {
      notifyResponseError(response, "Failed to load holder info");
    }
  };

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const updateModels = (models) => {
    setModels(models);
    setScannerNumbers([]);
  }

  const resetSerialNumberDependentData = () =>{
    setIsScannerKeyRequered(false);
    setScannerKey("");
    setScannerHasHolder(false);
    setHolderInfoWasEdited(false);
  }

  const handleClose = () => {
    setOpen(false);
    updateModels([]);
    setProducts([]);
    resetSerialNumberDependentData();
    setCurrentStep(0);
    setStep1Data({});
    setStep2Data({});
    setStep3Data({});
  };

 return (
    <>
      <Button style={{width}} variant="contained" onClick={handleClickOpen}>
        {children}
      </Button>
      {isLoading && <Loader />}
      <Dialog open={open} onClose={handleClose}>
        <Container style={{width: '500px'}} sx={styles.stepSubtitle}>
          <Stepper activeStep={currentStep}>
            <Step>
              <StepLabel>Step 1</StepLabel>
            </Step>
            <Step>
              <StepLabel>Step 2</StepLabel>
            </Step>
            <Step>
              <StepLabel>Step 3</StepLabel>
            </Step>
          </Stepper>
          <div>
            {currentStep === 0 && (
              <CreateLicenseStepOne
              setProducts={setProducts}
              loadProductByModel={loadProductByModel}
              loadHolderIfLicenseAlreadyExists={loadHolderIfLicenseAlreadyExists}
              setStep1Data={setStep1Data}
              step1Data={step1Data}
              handleNext={handleNext}
              models={models}
              updateModels={updateModels}
              deviceTypes={deviceTypes}
              scannerNumbers={scannerNumbers}
              setScannerNumbers={setScannerNumbers}
              resetSerialNumberDependentData={resetSerialNumberDependentData}
              isScannerKeyRequered={isScannerKeyRequered}
              setIsScannerKeyRequered={setIsScannerKeyRequered}
              setScannerKey={setScannerKey}
              scannerKey={scannerKey}
            />
            )}
            {currentStep === 1 && (
              <CreateLicenseStepTwo
              scannerHasHolder={scannerHasHolder}
              holderInfoWasEdited={holderInfoWasEdited}
              holders={holders}
              setHolderInfoWasEdited={setHolderInfoWasEdited}
              handleBack={handleBack}
              step2Data={step2Data}
              setStep2Data={setStep2Data}
              handleNext={handleNext}
            />
            )}
            {currentStep === 2 && (
              <CreateLicenseStepThree
                products={products}
                handleBack={handleBack}
                setStep3Data={setStep3Data}
                submit={onSubmit}
                step3Data={step3Data}
              />
            )}
          </div>
        </Container>
      </Dialog>
    </>
  );
}

export default CreateLicenseDialog;
