import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permissions: null,
};

const permissionsSlise = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
  },
});

export const {
  setPermissions
} = permissionsSlise.actions;

export default permissionsSlise.reducer;
