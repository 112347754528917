import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  Container,
  Grid,
  Autocomplete,
  Button,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import NotNullFieldFootnote from "../../../components/NotNullFieldFootnote";
import { step2Schema } from "../../../validationSchemas/createLicenseSchema";
import { yupResolver } from "@hookform/resolvers/yup";

const styles = {
  stepTitle: {
    textAlign: "center",
    marginY: 2,
  },
  stepSubtitle: {
    marginY: 3,
  },
  stepAction: {
    textAlign: "right",
    marginY: 2,
  },
};

const CreateLicenseStepTwo = ({
  scannerHasHolder,
  holderInfoWasEdited,
  holders,
  setHolderInfoWasEdited,
  handleBack,
  handleNext,
  step2Data,
  setStep2Data
}) => {
  const [disableCompanyName, setDisableCompanyName] = useState(false);

  const { setValue, getValues, control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(step2Schema),
  });

  useEffect(() => {
    setDisableCompanyName(scannerHasHolder);

    if (holderInfoWasEdited)
      onCompanyChange(step2Data?.company);

    if (scannerHasHolder) {
      onCompanyChange(holders[0].companyName);
    } else {
      setValue("company", "");
      setValue("contactPersonName", "");
      setValue("email", "");
      setValue("phoneNumber", "");
      setValue("detailes", "");
    }
  }, [scannerHasHolder]);

  useEffect(() => {
    setDisableCompanyName(scannerHasHolder);
    if (step2Data?.company) {
      setValue("company", step2Data.company);
      setValue("contactPersonName", step2Data.contactPersonName ?? "");
      setValue("email", step2Data.email ?? "");
      setValue("phoneNumber", step2Data.phoneNumber ?? "");
      setValue("detailes", step2Data.detailes ?? "");
    }
  }, [step2Data]);

  const onCompanyChange = (name) => {
    let selectedHolder = holders.find((h) => h.companyName === name);

    setValue(
      "company",
      selectedHolder ? selectedHolder?.companyName ?? "" : name
    );
    setValue("contactPersonName", selectedHolder?.contactPerson ?? "");
    setValue("email", selectedHolder?.email ?? "");
    setValue("phoneNumber", selectedHolder?.phoneNumber ?? "");
    setValue("detailes", selectedHolder?.comments ?? "");
  };

  const onSubmit = async (data) => {
    setStep2Data(data);
    handleNext();
  }
  
  const onHandleBack = () =>{
    setStep2Data(getValues());
    handleBack();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
    <Container>
      <Typography variant="h6" sx={styles.stepTitle}>
        Step 2: Holder Information
      </Typography>
      <Typography variant="body2" sx={styles.stepSubtitle}>
        Next, provide information about the person who is the license holder:
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            name="company"
            control={control}
            render={({ field }) => (
              <Autocomplete
                disabled={disableCompanyName}
                key={"Autocomplete" + field}
                {...field}
                value={getValues("company") || ""}
                freeSolo
                options={holders.map((option) => option.companyName)}
                getOptionLabel={(option) => option}
                onChange={(_, company) => {
                  field.onChange(company);
                  onCompanyChange(company);
                  setHolderInfoWasEdited(true);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    key={"TextField" + field.name}
                    {...params}
                    variant="outlined"
                    label="Company name"
                    error={Boolean(errors.company?.message)}
                    helperText={errors.company?.message}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      onCompanyChange(event.target.value);
                      setHolderInfoWasEdited(true);
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="contactPersonName"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                error={Boolean(errors.contactPersonName?.message)}
                fullWidth={true}
                type="text"
                label="Contact person (name & surname)"
                variant="outlined"
                helperText={errors.contactPersonName?.message}
                onChange={(event) => {
                  field.onChange(event.target.value);
                  setHolderInfoWasEdited(true);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                error={Boolean(errors.email?.message)}
                fullWidth={true}
                type="text"
                label="Email"
                variant="outlined"
                helperText={errors.email?.message}
                onChange={(event) => {
                  field.onChange(event.target.value);
                  setHolderInfoWasEdited(true);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="phoneNumber"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.phoneNumber?.message)}
                fullWidth={true}
                type="text"
                label="Phone number"
                variant="outlined"
                helperText={errors.phoneNumber?.message}
                onChange={(event) => {
                  field.onChange(event.target.value);
                  setHolderInfoWasEdited(true);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="detailes"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                fullWidth={true}
                rows={6}
                placeholder="Some comments..."
                onChange={(event) => {
                  field.onChange(event.target.value);
                  setHolderInfoWasEdited(true);
                }}
              />
            )}
          />
        </Grid>
        <NotNullFieldFootnote />
        <Container sx={styles.stepAction}>
          <Button onClick={onHandleBack}>Back</Button>
          <Button type='submit'>Next</Button>
        </Container>
      </Grid>
    </Container>
    </form>
  );
};

export default CreateLicenseStepTwo;
