import { Navigate, useNavigate } from "react-router-dom";
import { authAPI } from "../services/api/authAPI";
import { useState } from "react";
import { notifyResponseError } from "../helpers/notificationHelper";
import { useAuth } from "../hooks/useAuth";
import PasswordRecoveryForm from "../components/PasswordRecoveryForm";
import Loader from "../components/Loader/Loader";

function PasswordRecoveryPage() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuth } = useAuth();

  const onSubmit = async (data) => {
    setIsLoading(true);
    let response = await authAPI.restorePassword(data.email);
    if (response.status === 200) {
      navigate("/reset-password-confirmation");
    } else {
      notifyResponseError(response, "Failed to restore password");
    }
    setIsLoading(false);
  };

  if(isAuth){
    return <Navigate to="/" />
  }

  return (
    <div>
      <title>Reset password</title>
      <PasswordRecoveryForm onSubmit={onSubmit} isLoading={isLoading} />
      {isLoading && <Loader />}
    </div>
  );
}

export default PasswordRecoveryPage;
