import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import LicenseRow from "../components/LicenseRow";
import StyledHeadCell from "../components/Styled/StyledHeadCell";

const LicenseTable = (props) => {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHeadCell style={{ width: "34px" }}/>
              <StyledHeadCell>Scanner model</StyledHeadCell>
              <StyledHeadCell>Serial number</StyledHeadCell>
              <StyledHeadCell>License holder</StyledHeadCell>
              <StyledHeadCell/>
              <StyledHeadCell/>
              <StyledHeadCell style={{ width: "110px" }}/>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.collection
              .slice(0, props.collection.length)
              .map((item, index) => (
                <LicenseRow
                  index={index}
                  license={item}
                  canEditHolderInfo={props.canEditHolderInfo}
                  canEditCompanyInfo={props.canEditCompanyInfo}
                  toArchive={props.onDisable}
                  isLoading={props.isLoading}
                  onDownload={props.onDownload}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={props.totalCount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.onPageChanged}
          onRowsPerPageChange={props.onRowsPerPageChenged}
        />
      </TableContainer>
    </div>
  );
};

export default LicenseTable;