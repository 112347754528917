import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Button,
} from "@mui/material";
import React from "react";

const styles = {
  dialogTitle: {
    margin: "20px 20px 0 20px",
  },
  dialogActionsContainer: {
    justifyContent: "flex-end",
    margin: "0px 40px 20px 40px",
  },
  cancelButton: {
    width: "91px",
  },
  submitButton: {
    width: "91px",
  },
  backButtonGrid: {
    padding: "0",
  },
  applyButtonGrid: {
    padding: "0px 0px 0px 10px",
  },
};

const DialogForm = ({
  children,
  open,
  onClose,
  onSubmit,
  isSubmitting,
  title,
  applyButtonText,
  backButtonText,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <form onSubmit={onSubmit}>
        <DialogTitle style={styles.dialogTitle}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={3}
            style={styles.dialogActionsContainer}
            alignItems="center"
          >
            <Grid item style={styles.backButtonGrid}>
              <Button
                autoFocus
                onClick={onClose}
                color="primary"
                variant="outlined"
                style={styles.cancelButton}
              >
                {backButtonText}
              </Button>
            </Grid>
            <Grid item style={styles.applyButtonGrid}>
              <Button
                style={styles.submitButton}
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {applyButtonText}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogForm;
