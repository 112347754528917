import Footer from "./components/Footer/Footer";
import Header from "./components/Header";
import AppRoutes from "./routes/AppRoutes";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {AppStyles as s} from "./styles/main";

function App() {
  return (
    <div style={s.root}>
      <Header />
      <div style={s.flexibleContent}>
        <AppRoutes />
      </div>
      <Footer />
      <NotificationContainer/>
    </div>
  );
}

export default App;
