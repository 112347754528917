import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import SearchBar from "../components/SearchBar";
import useDeviceData from "../hooks/useDeviceData";
import ComboboxFilter from "../components/ComboboxFilter";
import TimePeriodFilter from "../components/TimePeriodFilter";
import {LicenseTableFilterBoxStyles as s} from "../styles/main";

const LicenseTableFilterBox = ({isLoading, onChange, data}) => {
  const [searched, setSearched] = useState(data?.search ?? "");
  const [from, setFrom] = useState(data?.startDate ?? null);
  const [to, setTo] = useState(data?.endDate ?? null);
  const [selectedCategories, setSelectedCategories] = useState(data?.scannerCategory ?? []);
  const [selectedTypes, setSelectedTypes] = useState(data?.scannerType ?? []);
  const { deviceCategories, deviceTypes } = useDeviceData();

  const cancelSearch = () => {
    requestSearch("");
  };

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    !searchedVal
      ? localStorage.removeItem("searchText")
      : localStorage.setItem("searchText", searchedVal);
      onChange(selectedCategories, selectedTypes, from, to, searchedVal);
  };

  const clearFilters = () => {
    setSelectedCategories([]);
    localStorage.removeItem("selectedCategories");
    setSelectedTypes([]);
    localStorage.removeItem("selectedTypes");
    setFrom(null);
    localStorage.removeItem("from")
    setTo(null);
    localStorage.removeItem("to")
    onChange([], [], null, null, searched);
  };

  const onCategoryFilterChanged = (categories) => {
    setSelectedCategories(categories);
    categories && categories.length > 0
      ? localStorage.setItem("selectedCategories", JSON.stringify(categories))
      : localStorage.removeItem("selectedCategories");
      onChange(categories, selectedTypes, from, to, searched);
  };

  const onTypeFilterChanged = (types) => {
    setSelectedTypes(types);
    types && types.length > 0
      ? localStorage.setItem("selectedTypes", JSON.stringify(types))
      : localStorage.removeItem("selectedTypes");
      onChange(selectedCategories, types, from, to, searched);
  };

  const onTimePeriodChange = (from, to) => {
    setFrom(from);
    setTo(to);
    from ? localStorage.setItem("from", from) : localStorage.removeItem("from");
    to ? localStorage.setItem("to", to) : localStorage.removeItem("to");
    onChange(selectedCategories, selectedTypes, from, to, searched);
  };

  return (
    <div style={s.container}>
      <div style={s.searchBarBox}>
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
      </div>
      <div style={s.filtersHeaderBox}>
        <Typography>Filters</Typography>
        <Button
          disabled={isLoading}
          variant="outlined"
          size="small"
          style={s.clearButton}
          onClick={clearFilters}
        >
          Clear
        </Button>
      </div>
      <ComboboxFilter
        label="Category"
        selectedItems={selectedCategories}
        items={deviceCategories}
        onChange={onCategoryFilterChanged}
      />
      <ComboboxFilter
        label="Type"
        selectedItems={selectedTypes}
        items={deviceTypes}
        onChange={onTypeFilterChanged}
      />
      <div style={s.timePeriodBox}>
        <Typography style={s.timePeriodBoxTitle}>Time period</Typography>
        <TimePeriodFilter
          from={from}
          to={to}
          onChange={onTimePeriodChange}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default LicenseTableFilterBox;
