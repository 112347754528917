import React, { useEffect } from "react";
import {
  TextField,
  Typography,
  Container,
  Grid,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import NotNullFieldFootnote from "../../../components/NotNullFieldFootnote";
import { yupResolver } from "@hookform/resolvers/yup";
import { step3Schema } from "../../../validationSchemas/createLicenseSchema";

const styles = {
  stepTitle: {
    textAlign: "center",
    marginY: 2,
  },
  stepSubtitle: {
    marginY: 3,
  },
  stepAction: {
    textAlign: "right",
    marginY: 2,
  },
  submitMuiButton: {
    height: '36,5px',
    backgroundColor: '#1976D2',
    color: '#ffffff',
    padding: '10px 17px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    textTransform: 'uppercase',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s',
    outline: 'none',
  },
};

const CreateLicenseStepThree = ({ products, handleBack, step3Data, setStep3Data, submit }) => {
  const { setValue, getValues, control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(step3Schema),
  });

  useEffect(() => {
    if (step3Data?.product) {
      setValue("product", step3Data.product);
      setValue("validTill", step3Data.validTill ?? null);
      setValue("generateOldVersion", step3Data.generateOldVersion);
    }
  }, [step3Data]);

  const onSubmit = async (data) => {
    submit(data);
  }

  const onHandleBack = () =>{
    setStep3Data(getValues());
    handleBack();
  }
  
  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <Container>
        <Typography variant="h6" sx={styles.stepTitle}>
          Step 3: License Information
        </Typography>
        <Typography variant="body2" sx={styles.stepSubtitle}>
          Finally, select the license and its validity period:
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="product"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  key={"Autocomplete" + field}
                  {...field}
                  value={getValues("product") ?? null}
                  options={products.map((option) => option)}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, name) => {
                    field.onChange(name);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      key={"TextField" + field.name}
                      {...params}
                      variant="outlined"
                      label="Product"
                      error={Boolean(errors.product?.message)}
                      helperText={errors.product?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="validTill"
              control={control}
              defaultValue={new Date().addYears(1)}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    {...field}
                    minDate={new Date().addDays(1)}
                    label="Valid till"
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        error={Boolean(errors.validTill?.message)}
                        helperText={errors.validTill?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="generateOldVersion"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getValues("generateOldVersion") ?? true}
                      onChange={(_, value) => {
                        field.onChange(value);
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      generate the license of the previous version (v.1.0.6)
                    </Typography>
                  }
                />
              )}
            />
          </Grid>
          <NotNullFieldFootnote />
          <Container sx={styles.stepAction}>
            <Button onClick={onHandleBack}>Back</Button>
            <input value='Submit' type='submit' style={styles.submitMuiButton}/>
          </Container>
        </Grid>
      </Container>
    </form>
  );
};

export default CreateLicenseStepThree;
