import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { companyAPI } from "../../services/api/companyAPI";
import { notifyResponseError, notifySuccess } from "../../helpers/notificationHelper";
import { Grid, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, InputLabel, IconButton, Autocomplete } from "@mui/material";
import Loader from "../Loader/Loader";
import { userAPI } from "../../services/api/userAPI";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../store/slices/usersSlise";
import NotNullFieldFootnote from "../NotNullFieldFootnote";

function EditUserDialog(props) {
  const {
    user,
    children
  } = props;

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const { users } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    name: yup.string().required().max(60).matches(/^((?!\s{2,}).)*$/, "Имя не должно содержать более одного пробела подряд"),
    email: yup.string().email().max(60).required(),
    companyName: yup.string().required(),
    phoneNumber: yup.string(),
    description: yup.string(),
  });
  
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loadUser = async () => {
    setIsLoading(true);
    let response = await userAPI.get(user.id);
    if (response.status === 200) {
      const preloads = parseResponseData(response);
      reset(preloads);
      setOpen(true);
    } else {
      handleClose();
      notifyResponseError(response, "Failed to load user");
    }
    setIsLoading(false);
  };

  const parseResponseData = (response) => {
    return { 
      name: response.data.name + " " + response.data.surname,
      email: response.data.email,
      companyName: response.data.company ? response.data.company.name : "",
      phoneNumber: response.data.phoneNumber ?? "",
      description: response.data.description ?? "",
     };
  }

  const onSubmit = async (data, id) => {
    setIsLoading(true);
    const user = users.find((element) => element.id === id);
    const userName = parseUserName(data.name);
    const newUser = {
      ...user,
      description: data.description,
      phoneNumber: data.phoneNumber,
      companyName: data.companyName,
      name: userName.name,
      surname: userName.surname,
      email: data.email,
    };
    let response = await userAPI.update(newUser);
    if (response.status === 200) {
      notifySuccess("User is updated");
      let response = await userAPI.get(newUser.id);
      if (response.status === 200) {
        dispatch(updateUser(response.data));
      } else {
        notifyResponseError(response, "Failed to get user");
      }
    } else {
      notifyResponseError(response, "Failed to update user");
    }
    setIsLoading(false);
    handleClose();
  };

  const parseUserName = (inputString) => {
    const spaceIndex = inputString.indexOf(" ");
    if (spaceIndex !== -1) {
      const firstWord = inputString.slice(0, spaceIndex);
      const restOfTheString = inputString.slice(spaceIndex + 1);
      return {name: firstWord, surname: restOfTheString};
    } else {
      return {name: inputString, surname: ""};
    }
  }

  const handleClickOpen = async () => {
    await loadUser();
    await loadCompanies();
  };

  const loadCompanies = async () => {
    setIsLoading(true);
    let response = await companyAPI.getAll();
    if (response.status === 200) {
      const companiesResult = [...new Set(response.data.map((item) => item))];
      setCompanies(companiesResult);
    } else {
      notifyResponseError(response, "Failed to load  companies");
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} disabled={props.disabled}>
        {children}
      </IconButton>
      {isLoading && <Loader />}
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <form onSubmit={handleSubmit((data) => onSubmit(data, user.id))}>
          <DialogTitle margin="20px 20px 0px 20px">
            Edit user info
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid item margin="0px 20px">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="user-name"
                      style={{ marginBottom: "8px" }}
                    >
                      User name *
                    </InputLabel>
                    <Controller
                      name="name"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          id="user-name"
                          error={Boolean(errors.name?.message)}
                          fullWidth={true}
                          type="text"
                          variant="outlined"
                          helperText={errors.name?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="email"
                      style={{ marginBottom: "8px" }}
                    >
                      Email *
                    </InputLabel>
                    <Controller
                      name="email"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          id="email"
                          error={Boolean(errors.email?.message)}
                          fullWidth={true}
                          type="text"
                          variant="outlined"
                          helperText={errors.email?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="company-name"
                      style={{ marginBottom: "8px" }}
                    >
                      Company name *
                    </InputLabel>
                    <Controller
                      name="companyName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          freeSolo
                          options={companies.map((option) => option.name)}
                          getOptionLabel={(option) => option}
                          onChange={(_, name) => {
                            field.onChange(name);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              type="text"
                              variant="outlined"
                              id="company-name"
                              fullWidth={true}
                              error={Boolean(errors.companyName?.message)}
                              helperText={errors.companyName?.message}
                              onChange={(event) => {
                                field.onChange(event.target.value);
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="phoneNumber"
                      style={{ marginBottom: "8px" }}
                    >
                      Phone
                    </InputLabel>
                    <Controller
                      name="phoneNumber"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth={true}
                          id="phoneNumber"
                          type="text"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="description"
                      style={{ marginBottom: "8px" }}
                    >
                      Details
                    </InputLabel>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline
                          id="description"
                          fullWidth={true}
                          rows={6}
                        />
                      )}
                    />
                  </Grid>
                  <NotNullFieldFootnote/>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              spacing={3}
              justifyContent="flex-end"
              margin="0px 40px 20px 40px"
              alignItems="center"
            >
              <Grid item style={{ padding: "0" }}>
                <Button
                  autoFocus
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                  style={{ width: "91px" }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item style={{ padding: "0px 0px 0px 10px" }}>
                <Button
                  style={{ width: "91px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default EditUserDialog;
