import {
  Grid,
  TextField,
  Button,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Loader from "../Loader/Loader";
import NotNullFieldFootnote from "../NotNullFieldFootnote";
import DialogForm from "../Dialogs/DialogForm";
import deviceSchema from "../../validationSchemas/deviceSchema";

function CreateDeviceDialog({devices, deviceCategories, isLoading, addDevice}) {
  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const [models, setModels] = useState([]);

  const {
    reset,
    getValues,
    control,
    setValue,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(deviceSchema),
  });

  const onSubmit = async (data) => {
    let isAdded = await addDevice(data);
    isAdded && handleClose();
  };

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    reset({ group: "", model: "", serialNumber: "", type: "" });
    setOpen(false);
  };

  const onCategoryChange = (group) => {
    setValue('group', group);
    let filteredDevices = devices.filter((d) => d.group === group);
    updateTypesCollection(
      filteredDevices.length === 0
        ? []
        : [...new Set(filteredDevices.map((item) => item.type))]
    );
  };

  const onTypeChange = (type) => {
    setValue("type", type);
    let filteredDevices = devices.filter((d) => d.type === type);
    updateModelsCollection(
      filteredDevices.length === 0
        ? []
        : [...new Set(filteredDevices.map((item) => item.model))]
    );
  };

  const updateTypesCollection = (data) => {
    setTypes(data);
    resetField("type");
    updateModelsCollection([]);
  };

  const updateModelsCollection = (data) => {
    setModels(data);
    resetField("model");
  };

  return (
    <>
      <Button variant="contained" onClick={handleClickOpen}>
        Add new device
      </Button>
      {isLoading && <Loader />}
      <DialogForm
        title="Add new device"
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isLoading}
        backButtonText="Cancel"
        applyButtonText="Save"
      >
        <Grid item margin="0px 20px">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel style={{ marginBottom: "8px" }}>
                Device category *
              </InputLabel>
              <Controller
                name="group"
                control={control}
                render={(param) => (
                  <Autocomplete
                    {...param}
                    freeSolo
                    value={getValues("group")}
                    key={"Autocomplete" + param.field.name}
                    options={deviceCategories}
                    getOptionLabel={(option) => option}
                    onChange={(_, group) => {
                      param.field.onChange(group);
                      onCategoryChange(getValues("group"));
                    }}
                    renderInput={(field) => (
                      <TextField
                        key={"TextField" + param.field.name}
                        {...field}
                        required
                        variant="outlined"
                        error={Boolean(errors.group?.message)}
                        helperText={errors.group?.message}
                        onChange={(event) => {
                          param.field.onChange(event.target.value);
                          onCategoryChange(event.target.value);
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel style={{ marginBottom: "8px" }}>
                Scanner type *
              </InputLabel>
              <Controller
                name="type"
                control={control}
                render={(param) => (
                  <Autocomplete
                    {...param}
                    freeSolo
                    value={getValues("type")}
                    key={"Autocomplete" + param.field.name}
                    options={types}
                    getOptionLabel={(option) => option}
                    onChange={(_, type) => {
                      param.field.onChange(type);
                      onTypeChange(getValues("type"));
                    }}
                    renderInput={(field) => (
                      <TextField
                        key={"TextField" + param.field.name}
                        {...field}
                        required
                        variant="outlined"
                        error={Boolean(errors.type?.message)}
                        helperText={errors.type?.message}
                        onChange={(event) => {
                          param.field.onChange(event.target.value);
                          onTypeChange(event.target.value);
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel style={{ marginBottom: "8px" }}>
                Scanner model *
              </InputLabel>
              <Controller
                name="model"
                control={control}
                render={(param) => (
                  <Autocomplete
                    {...param}
                    freeSolo
                    value={getValues("model")}
                    key={"Autocomplete" + param.field.name}
                    options={models}
                    getOptionLabel={(option) => option}
                    onChange={(_, model) => {
                      param.field.onChange(model);
                    }}
                    renderInput={(field) => (
                      <TextField
                        key={"TextField" + param.field.name}
                        {...field}
                        required
                        variant="outlined"
                        error={Boolean(errors.model?.message)}
                        helperText={errors.model?.message}
                        onChange={(event) => {
                          param.field.onChange(event.target.value);
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel style={{ marginBottom: "8px" }}>
                Serial number *
              </InputLabel>
              <Controller
                name="serialNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="serialNumber"
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    error={Boolean(errors.serialNumber?.message)}
                    helperText={errors.serialNumber?.message}
                  />
                )}
              />
            </Grid>
            <NotNullFieldFootnote />
          </Grid>
        </Grid>
      </DialogForm>
    </>
  );
}

export default CreateDeviceDialog;
