import { NotificationManager } from 'react-notifications';

export const notifyResponseError = (response, title) => {
    let message = response.data.message ? response.data.message : response.statusText;
    NotificationManager.error(`${response.status} ${message}`, title);
};

export const notifyError = (message, title) => {
    NotificationManager.error(message, title);
};

export const notifySuccess = (message) => {
    NotificationManager.success(message, "Success");
};

export const notifyInfo = (message) => {
    NotificationManager.info(message, "Info");
};

export const notifyWarning = (message) => {
    NotificationManager.warning(message, "Warning");
};