Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.addMonths = function (months) {
  const date = new Date(this.valueOf());
  date.setMonth(date.getMonth() + months);
  return date;
};

Date.prototype.addYears = function (years) {
  const date = new Date(this.valueOf());
  date.setFullYear(date.getFullYear() + years);
  return date;
};

Date.prototype.removeDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};

Date.prototype.removeMonths = function (months) {
  const date = new Date(this.valueOf());
  date.setMonth(date.getMonth() - months);
  return date;
};

Date.prototype.removeYears = function (years) {
  const date = new Date(this.valueOf());
  date.setFullYear(date.getFullYear() - years);
  return date;
};

Date.prototype.withoutTime = function () {
  return new Date(this.getFullYear(), this.getMonth(), this.getDate());
};

module.exports = Date;