import $api from "../axios";
import { handleResponse } from "../../helpers/httpHelpers";

export const appConfigurationAPI = {
  getSettings
};

async function getSettings() {
  try {
    return await $api.get(`/AppConfiguration`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}
