import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as Date from "../helpers/datePrototypes";

const styles = {
  periodButton: {
    height: "25px",
    textTransform: "none",
    color: "grey",
    borderColor: "grey",
    marginRight: "2px",
    marginBottom: "2px"
  },
  datePickerContainer: {
    marginBottom: "10px",
  },
};

const TimePeriodFilter = ({
  from,
  to,
  onChange,
  isLoading,
}) => {

  const [resetFromKey, setResetFromKey] = useState(0);
  const [resetToKey, setResetToKey] = useState(0);

  // эти useEffect были добавлены, так как после невалидных данных
  // при очищении значений from и to поля DesktopDatePicker не очищались
  useEffect(() => {
    if(from == null)
      setResetFromKey(prev => prev + 1); // изменяем ключ для перерисовки
  }, [from]);

  useEffect(() => {
    if(to == null)
      setResetToKey(prev => prev + 1); // изменяем ключ для перерисовки
  }, [to]);

  const formatDate = (data) => {
    if(data == null) return null;
    const timestamp = Date.UTC(data.getFullYear(), data.getMonth(), data.getDate(), 0, 0, 0, 0);
    const utcDate = new Date(timestamp);
    return utcDate;
  }

  return (
    <>
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div style={styles.datePickerContainer}>
            <DesktopDatePicker
              key={`from-${resetFromKey}`} // уникальный ключ для перерисовки
              label="From"
              inputFormat="dd/MM/yyyy"
              value={from}
              onChange={(value) => onChange(formatDate(value), to)}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div style={styles.datePickerContainer}>
            <DesktopDatePicker
              key={`to-${resetToKey}`} // уникальный ключ для перерисовки
              label="To"
              maxDate={new Date()}
              inputFormat="dd/MM/yyyy"
              value={to}
              onChange={(value) => onChange(from, formatDate(value))}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </LocalizationProvider>
      </div>
      <div>
        <Button
          disabled={isLoading}
          variant="outlined"
          size="small"
          style={styles.periodButton}
          onClick={() => onChange(formatDate(new Date().removeMonths(1)), formatDate(new Date()))}
        >
          Month
        </Button>
        <Button
          disabled={isLoading}
          variant="outlined"
          size="small"
          style={styles.periodButton}
          onClick={() => onChange(formatDate(new Date().removeMonths(6)), formatDate(new Date()))}
        >
          Six month
        </Button>
        <Button
          disabled={isLoading}
          variant="outlined"
          size="small"
          style={styles.periodButton}
          onClick={() => onChange(formatDate(new Date().removeYears(1)), formatDate(new Date()))}
        >
          Year
        </Button>
        <Button
          disabled={isLoading}
          variant="outlined"
          size="small"
          style={styles.periodButton}
          onClick={() => onChange(formatDate(new Date().removeYears(5)), formatDate(new Date()))}
        >
          Five years
        </Button>
      </div>
    </>
  );
};

export default TimePeriodFilter;
