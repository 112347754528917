import { Toolbar, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { appConfigurationAPI } from "../../services/api/appConfigurationAPI";
import s from "./Footer.module.css";

function Footer() {
  const [email, setEmail] = useState(null);

  useEffect(async () => {
    if (email) return;
    await loadSettings();
  }, [email]);

  const loadSettings = async () => {
    let response = await appConfigurationAPI.getSettings();
    if (response.status === 200) {
      setEmail(response.data.supportAddress);
    }
  };

  return (
    <div>
      {email && <div className={s.container}>
        <Toolbar className={s.toolbar}>
          <div className={s.label}>Contact Technical Support:</div>
          <Link color="inherit" href={`mailto:${email}`}>
            {email}
          </Link>
        </Toolbar>
      </div>}
      <div className={s.container}>
        <Toolbar />
      </div>
    </div>
  );
}

export default Footer;
