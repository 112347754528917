import * as Yup from 'yup';

const phoneSchema = Yup.lazy((value) => {
    if (value !== null && value !== undefined && Boolean(value)) {
      return Yup.string()
        .matches(/^[^\s].*[^\s]$/, 'The phone number must not start or end with a space')
        .min(4, 'Phone number must be at least 4 characters')
        .max(17, 'Phone number must be at most 17 characters');
    }
    return Yup.mixed();
  });
  
export default phoneSchema;