import { Backdrop } from '@mui/material';
import React from 'react';
import style from './Loader.module.css';

const Loader = () => {
    return (
        <Backdrop open={true} style={{background: "rgba(0, 0, 0, 0)"}}>
            <div className={style.main}>
                <div className={style.one}>
                    <div className={style.two}>
                        <div></div>
                    </div>
                </div>
            </div>
      </Backdrop>
    );
};

export default Loader;