import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import StyledHeadCell from "../components/Styled/StyledHeadCell";
import ArchiveRow from "./ArchiveRow";

const ArchiveTable = (props) => {
  return (
    <div>
      <TableContainer>
      <div style={{ maxHeight: "748px", overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHeadCell style={{ width: "34px" }}/>
              <StyledHeadCell>Scanner model</StyledHeadCell>
              <StyledHeadCell>Serial number</StyledHeadCell>
              <StyledHeadCell>License holder</StyledHeadCell>
              <StyledHeadCell/>
              <StyledHeadCell style={{ width: "110px" }}/>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.collection
              .slice(0, props.collection.length)
              .map((item, index) => (
                item.products.some(p => p.archived) && <ArchiveRow
                  index={index}
                  license={item}
                  canEditInfo={props.canEditHolderInfo}
                  canDeleteProduct={props.canDeleteProduct}
                  removeProduct={props.onDelete}
                  isLoading={props.isLoading}
                />
              ))}
          </TableBody>
        </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={props.totalCount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.onPageChanged}
          onRowsPerPageChange={props.onRowsPerPageChenged}
        />
      </TableContainer>
    </div>
  );
};

export default ArchiveTable;