import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Link,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import DateWithExpiration from "./DateWithExpiration/DateWithExpiration";
import StyledTableCell from "./Styled/StyledTableCell";
import ActionIconButton from "./ActionIconButton";
import { DEFAULT_STRING } from "../constants/defaultStrings";
import { Link as ButtonLink } from "react-router-dom";

const ArchiveRow = ({
  index,
  license,
  isLoading,
  removeProduct,
  canEditInfo,
  canDeleteProduct
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [license?.id]);

  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <>
      <TableRow>
        <StyledTableCell>
          <IconButton size="small" onClick={handleToggle}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>
          {license.device.model}
        </StyledTableCell>
        <StyledTableCell>
          {license.device.serialNumber}
        </StyledTableCell>
        <StyledTableCell>
          {license.holder && (
            <Tooltip
              title={
                <>
                  <Box style={{ margin: "4px" }}>{`${
                    license.holder?.contactPerson || ""
                  }`}</Box>
                  <Box style={{ margin: "4px" }}>
                    {license.holder?.email || ""}
                  </Box>
                  <Box style={{ margin: "6px" }}>
                    {license.holder?.phoneNumber || ""}
                  </Box>
                  <Box style={{ margin: "4px" }}>
                    {license.holder?.comments || ""}
                  </Box>
                </>
              }
              placement="bottom-start"
            >
              {canEditInfo ? (
                <Link
                  color="inherit"
                  target="_blank"
                  href={`/holder/${license.holder.id}`}
                  sx={{
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  <Box>
                    {license.holder.companyName || DEFAULT_STRING.NO_NAME}
                  </Box>
                </Link>
              ) : (
                <Box>
                  {license.holder.companyName || DEFAULT_STRING.NO_NAME}
                </Box>
              )}
            </Tooltip>
          )}
        </StyledTableCell>
        <StyledTableCell/>
        <StyledTableCell align="center">
          <Button
            component={ButtonLink}
            to={`/history/${license.device.model}/${license.device.serialNumber}`}
            disabled={isLoading}
            variant="outlined"
            target="_blank"
            size="small"
            style={{
              height: "25px",
              width: "110px",
              textTransform: "none",
              color: "grey",
              borderColor: "grey",
            }}
          >
            View history
          </Button>
        </StyledTableCell>
      </TableRow>
      {license.products?.map((product, productIndex) => 
         open && (
            <TableRow height="41px">
              <StyledTableCell style={{ width: "34px" }} />
              <StyledTableCell>
                {product.name === "" ? DEFAULT_STRING.NO_NAME : product.name}
              </StyledTableCell>
              <StyledTableCell>
                <Tooltip
                  title={
                    <>
                      <Box
                        style={{ margin: "4px" }}
                        color={
                          product.creator?.isDisable
                            ? "text.secondary"
                            : "white"
                        }
                      >{`${product.creator?.name || ""} ${
                        product.creator?.surname || ""
                      }`}</Box>
                      <Box style={{ margin: "4px" }}>
                        {product.creator?.email || ""}
                      </Box>
                      <Box style={{ margin: "6px" }}>
                        {product.creator?.phoneNumber || ""}
                      </Box>
                      <Box style={{ margin: "4px" }}>
                        {product.creator?.description || ""}
                      </Box>
                    </>
                  }
                  placement="bottom-start"
                >
                  <Box>
                    {product.creator?.companyName || DEFAULT_STRING.UNKNOWN}
                  </Box>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell>
                <Box display="flex" flexDirection="row">
                  <InputLabel style={{ fontSize: "12px", marginRight: "8px", marginTop: "2px" }}>
                    Issue date
                  </InputLabel>
                  {new Date(product.creationTime.replace("Z", "")).toLocaleDateString("ru-RU")}
                </Box>
              </StyledTableCell>
              <StyledTableCell>
              <Box display="flex" flexDirection="row" height="24px">
                  <InputLabel
                    style={{
                      fontSize: "12px",
                      marginRight: "8px",
                      marginTop: "4px",
                    }}
                  >
                    Valid till
                  </InputLabel>
                  <DateWithExpiration
                    date={new Date(product.validTill.replace("Z", ""))}
                  />
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                {canDeleteProduct && <Box textAlign="center">
                  <ActionIconButton
                    title="Delete this license product?"
                    contentText="If you delete the product, you couldn't revert this action. Information about this license product will still be in the history."
                    confirmText="Delete"
                    rejectText="Cancel"
                    onClick={() => removeProduct(product.id)}
                  >
                    <DeleteIcon />
                  </ActionIconButton>
                </Box>}
              </StyledTableCell>
            </TableRow>)
      )}
    </>
  );
};

export default ArchiveRow;
