import React, { useState } from "react";
import { Collapse, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CustomCheckbox from "./Styled/CustomCheckbox";

const styles = {
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  leftMargin: {
    marginLeft: "25px",
  },
};

const ComboboxFilter = ({ items, label, selectedItems, onChange }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const changeSelectedItems = (item) => {
    let updatedData = selectedItems.includes(item)
      ? selectedItems.filter((element) => element !== item)
      : [...selectedItems, item];
    onChange(updatedData);
  };

  return (
    <>
      <div style={styles.flexContainer}>
        <IconButton size="small" onClick={handleToggle}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Typography>{label}</Typography>
      </div>
      <div style={styles.flexContainer}>
        <div style={styles.leftMargin}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {items?.map((item, index) => (
              <div key={index} style={styles.flexContainer}>
                <CustomCheckbox
                  checked={selectedItems.includes(item)}
                  onChange={() => changeSelectedItems(item)}
                />
                {item}
              </div>
            ))}
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default ComboboxFilter;
