import LoginForm from "../components/LoginForm";
import { Navigate, useNavigate } from "react-router-dom";
import { authAPI } from "../services/api/authAPI";
import { useState } from "react";
import { notifyResponseError } from "../helpers/notificationHelper";
import { useAuth } from "../hooks/useAuth";
import { useDispatch } from "react-redux";
import { setPermissions } from "../store/slices/permissionsSlise";

function LoginPage() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuth } = useAuth();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setIsLoading(true);
    let response = await authAPI.login(data);
    if (response.status === 200) {
      localStorage.setItem("user", JSON.stringify(response.data));
      dispatch(setPermissions(response.data.permissions));
      navigate("/");
    } else {
      notifyResponseError(response, "Failed to login");
    }
    setIsLoading(false);
  };

  if(isAuth){
    return <Navigate to="/" />
  }

  return (
    <div>
      <title>Login</title>
      <LoginForm onSubmit={onSubmit} isLoading={isLoading} />
    </div>
  );
}

export default LoginPage;
