import { useEffect, useState } from "react";
import { deviceAPI } from "../services/api/deviceAPI";
import { notifyResponseError, notifyError, notifySuccess } from "../helpers/notificationHelper";

const useDeviceData = () => {
  const [devices, setDevices] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [deviceModels, setDeviceModels] = useState([]);
  const [deviceCategories, setDeviceCategories] = useState([]);
  const [deviceSerialNumbers, setDeviceSerialNumbers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const setState = (data) => {
    setDevices(data);
    let uniqueCategories = [...new Set(data.map((item) => item.group))];
    let uniqueTypes = [...new Set(data.map((item) => item.type))];
    let uniqueModels = [...new Set(data.map((item) => item.model))];
    let uniqueNumbers = [...new Set(data.map((item) => item.serialNumber))];
    setDeviceCategories(uniqueCategories);
    setDeviceTypes(uniqueTypes);
    setDeviceModels(uniqueModels);
    setDeviceSerialNumbers(uniqueNumbers);
  };

  useEffect(async () => {
    if (devices.length === 0) {
      setIsLoading(true);
      try {
        const response = await deviceAPI.getAll();
        if (response.status === 200) {
          setState(response.data.reverse());
        } else {
          notifyResponseError(response, "Failed to load devices");
        }
      } catch (error) {
        notifyError(error, "Failed to load devices");
      }
      setIsLoading(false);
    }
  }, []);

  const addDevice = async (device) => {
    let result = false;
    setIsLoading(true);
    try{
      let response = await deviceAPI.create(device);
      if (response.status === 200) {
        notifySuccess("Device is created");
        let response = await deviceAPI.get({model: device.model, serialNumber: device.serialNumber});
        if (response.status === 200) {
          setState([response.data[0], ...devices]);
          result = true;
        } else {
          notifyResponseError(response, "Failed to get device");
        }
      } else {
        notifyResponseError(response, "Failed to create device");
      }
    }catch(error){
      notifyError(error, "Failed to create device");
    }
    setIsLoading(false);
    return result;
  };

  return {
    devices,
    deviceCategories,
    deviceTypes,
    deviceModels,
    deviceSerialNumbers,
    isLoading,
    addDevice
  };
};

export default useDeviceData;
