import * as yup from "yup";

const holderSchema = yup.object().shape({
  companyName: yup
    .string()
    .required(),
  name: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required(),
  comment: yup.string().required(),
});

export default holderSchema;
